import {
  SETUP_MENU,
  CHANGE_SECTION,
  TOGGLE_MOBILE,
  UPDATE_IN_VIEW_MENU_ITEM,
  PROMPT_LOGOUT,
  USER_LOGOUT,
} from '@actions';

const initialState = {
  items: {
    login: {
      label: 'Sign Up/Login',
      enabled: false,
      route: 'login',
    },
    register: {
      label: 'Register',
      enabled: false,
      route: 'register',
    },
    generalInfo: {
      label: 'General Info',
      enabled: true,
      route: '',
    },
    videos: {
      label: 'Videos',
      enabled: true,
      route: 'videos',
    },
    schedule: {
      label: 'Schedule',
      enabled: true,
      route: 'schedule',
    },
    speakers: {
      label: 'Speakers',
      enabled: true,
      route: 'speakers',
    },
    abstractManagement: {
      label: 'Abstracts',
      enabled: true,
      route: 'abstracts',
    },
    viewAbstract: {
      label: 'Abstracts',
      enabled: false,
      route: 'abstractsview',
      hidden: true,
    },
    previewAbstract: {
      label: 'Abstracts',
      enabled: false,
      route: 'abstractspreview',
      hidden: true,
    },
    viewVideo: {
      label: 'Videos',
      enabled: false,
      route: 'videosview',
      hidden: true,
    },
    hotelManagement: {
      label: 'Accomodation',
      enabled: false,
      route: 'accomodation',
    },
    floormap: {
      label: 'Floor Map',
      enabled: false,
      route: 'floormap',
    },
    social: {
      label: 'Social',
      enabled: false,
      route: 'social',
    },
    billing: {
      label: 'Payments',
      enabled: false,
      route: 'billing',
    },
    account: {
      label: 'My Account',
      enabled: true,
      hidden: true,
      route: 'account',
    },
    sponsors: {
      label: 'Sponsors',
      enabled: true,
      route: 'sponsors',
    },
    ebag: {
      label: 'E-bag',
      enabled: true,
      route: 'ebag',
    },
    products: {
      label: 'Products',
      enabled: false,
      hidden: false,
      route: 'products',
    },
    exhibition: {
      label: 'Exhibition',
      enabled: true,
      route: 'exhibition',
    },
  },
  itemsInView: {
    generalInfo: false,
    videos: false,
    schedule: false,
    speakers: false,
    abstractManagement: false,
    hotelManagement: false,
    sponsors: false,
    ebag: false,
    products: false,
    exhibition: false,
  },
  initialized: false,
  active: 'generalInfo',
  popstate: false,
  mobileToggled: false,
  promptLogout: false,
  pageProps: null,
};

const types = {
  generalInfo: 'generalInfo',
  exhibition: 'exhibition',
  products: 'products',
  sponsors: 'sponsors',
  ebag: 'ebag',
  videos: 'videos',
  schedule: 'schedule',
  speakers: 'speakers',
  abstractManagement: 'abstracts',
  viewAbstract: 'abstracts',
  hotelManagement: 'accomodation',
  billing: 'payments',
  login: 'signupLogin',
};

const menu = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_MOBILE: {
      return Object.assign({}, state, {
        mobileToggled: window.innerWidth <= 834 ? true : false,
      });
    }
    case PROMPT_LOGOUT: {
      return Object.assign({}, state, {
        promptLogout: true,
      });
    }
    case USER_LOGOUT: {
      return Object.assign({}, state, {
        promptLogout: false,
      });
    }

    case UPDATE_IN_VIEW_MENU_ITEM: {
      return Object.assign({}, state, {
        itemsInView: { ...state.itemsInView, [action.key]: action.status },
      });
    }

    case CHANGE_SECTION: {
      return Object.assign({}, state, {
        active: action.section,
        popstate: action.popstate,
        mobileToggled: false,
        pageProps: action.pageProps,
      });
    }

    case SETUP_MENU: {
      const newState = JSON.parse(JSON.stringify(state));
      const userHasReservation =
        Object.keys(action.user?.data?.accomodation || {}).length > 0;

      newState.items.login = Object.assign({}, newState.items.login, {
        enabled: false,
      });
      Object.keys(newState.items).map((k) => {
        newState.items[k].label = window.window.lang[action.language][types[k]];
      });

      Object.entries(action.event).map(([key, value]) => {
        if (newState.items.hasOwnProperty(key)) {
          newState.items[key] = Object.assign({}, newState.items[key], {
            enabled:
              ((action.policy || {}).type === 'public' ||
                !action.user.data.id) &&
              (key === 'hotelManagement' ||
                key === 'billing' ||
                key === 'viewAbstract')
                ? false
                : !!value,
          });
        }
      });

      //check if the event has any identifier to add signup/login
      if (
        ((action.policy || {}).fields || []).filter((f) => f.identifier)
          .length === 0
      ) {
        newState.items['login'].enabled = false;
      }

      // check if event has available online hotels
      if (!action.event.hasOnlineHotels && !userHasReservation) {
        newState.items['hotelManagement'].enabled = false;
      }

      // check if event has published schedule
      if (!action.event.publishSpeakers) {
        newState.items['speakers'].enabled = false;
      } else {
        newState.items['speakers'].enabled = true;
      }
      if (!action.event.publishSchedule) {
        newState.items['schedule'].enabled = false;
      } else {
        newState.items['schedule'].enabled = true;
      }

      // check if event has published schedule
      if (!action.event.publishVideos || !action.user.data.id) {
        newState.items['videos'].enabled = false;
      } else {
        newState.items['videos'].enabled = true;
      }
      if (
        action.event.clientPanelSettings?.sponsors.sponsorsSchema.length > 0
      ) {
        newState.items['sponsors'].enabled = true;
      } else {
        newState.items['sponsors'].enabled = false;
      }
      let ebagSchema;
      if (action.event.clientPanelEbagSettings) {
        ebagSchema = action.event.clientPanelEbagSettings;
        try {
          ebagSchema = JSON.parse(ebagSchema);
          newState.items['ebag'].enabled = !!ebagSchema.enableEbagTab;
        } catch (e) {
          ebagSchema = {};
          newState.items['ebag'].enabled = false;

          console.error('Error parsing the old ebag schema!');
        }
      } else if (action.event.clientPanelSettings?.ebag.ebagSchema.length > 0) {
        newState.items['ebag'].enabled = true;
      } else {
        newState.items['ebag'].enabled = false;
      }
      if (action.event?.clientPanelSettings?.products?.showProducts) {
        newState.items['products'].enabled = true;
      } else {
        newState.items['products'].enabled = false;
      }
      if (action.event?.clientPanelSettings?.exhibition?.showExhibition) {
        newState.items['exhibition'].enabled = true;
      } else {
        newState.items['exhibition'].enabled = false;
      }
      newState.initialized = true;

      return newState;
    }

    default: {
      return state;
    }
  }
};

export default menu;
