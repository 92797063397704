import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getSubscriptions } from '@actions';
import moment from 'moment';

class Pricing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriptions: [],
      eventPeriods: {},
      activePeriod: '',
    };
  }

  componentDidMount() {
    const { event } = this.props;
    const now = moment.utc().format('YYYY-MM-DD HH:mm:ss');
    const eventPeriods = {};
    this.props.getSubscriptions(event.id).then((d) => {
      const subscriptions = d.data.map((s) => ({
        id: s.id,
        typeName: s.typeName,
        periodName: s.periodName,
        startDate: moment(s.startDate.tz).format('DD-MM-Y HH:mm'),
        endDate: moment(s.endDate.tz).format('DD-MM-Y HH:mm'),
        price: `${s.price} ${event.currencySymbol}`,
        timezone: s.startDate.timezone,
      }));
      let activePeriod = '';
      let lastPeriod = '';
      subscriptions.map((sub) => {
        eventPeriods[sub.periodName] = {
          [sub.typeName]: { ...sub },
          ...eventPeriods[sub.periodName],
          periodEnd: sub.endDate,
          periodStart: sub.startDate,
        };
        if (moment(now).isBetween(sub.periodStart, sub.periodEnd)) {
          activePeriod = sub.periodName;
        }
        lastPeriod = sub.periodName;
      });
      // In case we have no match in a specific period
      // i just set as active the last one
      if (activePeriod === '') {
        activePeriod = lastPeriod;
      }
      this.setState({ subscriptions, eventPeriods, activePeriod });
    });
  }
  render() {
    const { subscriptions, eventPeriods, activePeriod } = this.state;
    const now = moment.utc().format('YYYY-MM-DD HH:mm:ss');

    const activePeriodStart =
      eventPeriods[activePeriod] &&
      moment(eventPeriods[activePeriod].periodStart, 'DD-MM-YYYY HH:mm');
    const activePeriodEnd =
      eventPeriods[activePeriod] &&
      moment(eventPeriods[activePeriod].periodEnd, 'DD-MM-YYYY HH:mm');

    const isActiveNow =
      activePeriodStart &&
      activePeriodEnd &&
      moment(now, 'YYYY-MM-DD HH:mm:ss').isBetween(
        activePeriodStart,
        activePeriodEnd
      );

    return (
      <React.Fragment>
        <div className="col-sm-12">
          <p className="section-title price-title">Pricing</p>
          <div className="period-container">
            {Object.keys(eventPeriods).map((key) => {
              return (
                <div
                  key={key}
                  className={`period-name ${
                    key === this.state.activePeriod ? 'active' : ''
                  }`}
                  onClick={() => this.setState({ activePeriod: key })}>
                  {key}
                </div>
              );
            })}
          </div>
        </div>
        <div
          className="card-container pricing"
          style={
            eventPeriods[activePeriod] &&
            Object.keys(eventPeriods[activePeriod]).length > 3
              ? null
              : { justifyContent: 'center' }
          }>
          {subscriptions &&
            eventPeriods[activePeriod] &&
            Object.keys(eventPeriods[activePeriod]).map((key, i) => {
              const period = eventPeriods[activePeriod][key];
              if (i < 4 && key !== 'periodEnd' && key !== 'periodStart') {
                return (
                  <div className="pricing-card-wrapper" key={i}>
                    <div
                      className={`col-sm-3 pricing-card ${
                        isActiveNow ? '' : 'disabled'
                      }`}
                      key={period.id}>
                      <h2 className="price-group">{period.typeName}</h2>
                      <h2 className="price">{period.price}</h2>
                      <button
                        className={`availability-button ${
                          isActiveNow ? '' : 'disabled'
                        }`}>
                        {isActiveNow ? 'Available' : 'Unavailable'}
                      </button>
                    </div>
                  </div>
                );
              }
            })}
        </div>
      </React.Fragment>
    );
  }
}
Pricing.propTypes = {
  event: PropTypes.object.isRequired,
  getSubscriptions: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.api.user.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getSubscriptions: (eventId) => dispatch(getSubscriptions(eventId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Pricing);
