import React from 'react';
import PropTypes from 'prop-types';
import { InView } from 'react-intersection-observer';
// import { colorPalette } from "@config/colorPalette";

const ObservableMenuItem = (props) => {
  return (
    <InView>
      {({ inView, ref }) => {
        if (props.isInView !== inView) {
          props.updateInViewMenuItem(props.item.key, inView);
        }

        return (
          <div
            ref={ref}
            key={`nav-item-${props.item.key}`}
            className={`nav-item${
              props.active === props.item.key ? ' active' : ''
            }`}
            onClick={() => {
              props.changeSection(props.item.key);
              props.changeScreen(props.item.key);
            }}>
            <p
              lang={props.language}
              style={{
                color:
                  props.active === props.item.key &&
                  window.colorPalette.primary[600],
              }}>
              {props.item.label}
            </p>
          </div>
        );
      }}
    </InView>
  );
};

ObservableMenuItem.propTypes = {
  language: PropTypes.string.isRequired,
  isInView: PropTypes.bool.isRequired,
  active: PropTypes.string.isRequired,
  changeSection: PropTypes.func.isRequired,
  changeScreen: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  updateInViewMenuItem: PropTypes.func.isRequired,
};

export default ObservableMenuItem;
