import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from '@material-ui/core/Modal';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import Button from '@material-ui/core/Button';
import { updateUser, getUser } from '@actions';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Backdrop } from '@material-ui/core';
import { AccountIcon } from '@dynamic_assets/accounticon.svg';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { checkIfConditionIsFulfilled } from '../../../../../../helpers';

const EditInfoModal = (props) => {
  const [sendingStatus, setSendingStatus] = React.useState(false);
  const [userInfo, setUserInfo] = React.useState(props.user.info);

  const returnField = (field) => {
    switch (field.type) {
      case 'yes_no':
        return (
          <RadioGroup
            value={userInfo[field.key]}
            onChange={(e) => updateFieldsWithEvent(e, field)}>
            <FormControlLabel
              className="radio-coloring"
              value="1"
              control={<Radio />}
              label="Yes"
            />
            <FormControlLabel
              className="radio-coloring"
              value="0"
              control={<Radio />}
              label="No"
            />
          </RadioGroup>
        );
      case 'dropdown':
        return (
          <Select
            id="view-select"
            variant="outlined"
            value={userInfo[field.key]}
            onChange={(e) => updateFieldsWithEvent(e, field)}>
            {!field.required && (
              <MenuItem key={'null-value-selection'} value={''}>
                N/A
              </MenuItem>
            )}
            {field.value.map((field_value, i) => (
              <MenuItem key={`${i}-value-selection`} value={field_value}>
                {field_value}
              </MenuItem>
            ))}
          </Select>
        );
      case 'textarea':
        return (
          <textarea
            className="form-control text-area edit-field-text-area"
            rows="5"
            onChange={(e) => updateFieldsWithEvent(e, field)}
            defaultValue={userInfo[field.key]}
          />
        );
      case 'text':
      case undefined:
        return (
          <TextField
            defaultValue={userInfo[field.key]}
            multiline={field.type === 'textarea'}
            disabled={field.key === 'email' && props.policy.type === 'private'}
            id="outlined-basic"
            variant="outlined"
            onChange={(e) => updateFieldsWithEvent(e, field)}
          />
        );
    }

    return field.type;
  };

  const updateFieldsWithEvent = (changeEvent, field) => {
    const userInfoTemp = { ...userInfo };
    userInfoTemp[field.key] = changeEvent.target.value;
    setUserInfo(userInfoTemp);
  };

  const saveChanges = () => {
    if (sendingStatus) return;
    setSendingStatus(true);
    props
      .updateUser(props.event.id, props.policy.id, props.user.id, {
        info: userInfo,
      })
      .then(() => {
        props.getUser();
        props.closeModal();
        setSendingStatus(false);
      })
      .catch(() => {
        setSendingStatus(false);
      });
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={'myModal'}
      open={props.modalIsOpen}
      onClose={props.closeModal}
      hideBackdrop={true}>
      <div className="edit-info-container">
        <Backdrop
          className="backdrop-edit-info"
          open={props.modalIsOpen}
          onClick={(e) => {
            e.stopPropagation();
            props.closeModal();
          }}>
          <div
            className="edit-info-wrapper"
            onClick={(e) => {
              e.stopPropagation();
            }}>
            <div className="edit-info-header">
              {lang[props.language]['editYourProfile']}
              <CloseRoundedIcon
                className="close-edit-info"
                onClick={props.closeModal}
              />
            </div>
            <div className="modal-content-wrapper">
              <div className="account-profile">
                {/* UPDATE SECTION WHEN PROFILE PICS ARE AVAILABLE */}
                <div className="avatar-wrapper">
                  <div className="user-account-no-avatar">
                    <AccountIcon color={window.colorPalette.primary[600]} />
                  </div>
                </div>
                {/* UPDATE SECTION WHEN PROFILE PICS ARE AVAILABLE */}
                {/* <Button
                  onClick={() => {}}
                  className="update-profile-button"
                  variant="contained"
                >
                  {lang[props.language]["update"]}
                </Button>

                <div onClick={() => {}} className="remove-profile-button">
                  {lang[props.language]["remove"]}
                </div> */}
              </div>
              <div className="account-fields">
                {props.policy?.fields?.map((field) => {
                  if (
                    field.key === 'password' ||
                    (field.key === 'username' && props.policy.type === 'public')
                  )
                    return;
                  // todo change of state in render is a bad practice. Consider another implementation
                  const conditionFulfilled = checkIfConditionIsFulfilled(
                    field,
                    userInfo
                  );
                  if (!conditionFulfilled) {
                    if (userInfo[field.key] !== '')
                      setUserInfo({ ...userInfo, [field.key]: '' });
                    return null;
                  }

                  return (
                    <div
                      className={`user-edit-field user-field-${field.key}`}
                      key={`user-edit-field-${field.key}`}>
                      <div className="edit-field-title">{field.name}</div>
                      <div className="edit-field">{returnField(field)}</div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="edit-info-actions-wrapper">
              <Button
                onClick={props.closeModal}
                className="action-button cancel-edit-button"
                variant="contained">
                {lang[props.language]['cancel']}
              </Button>
              <Button
                onClick={saveChanges}
                className="action-button save-edit-button"
                variant="contained">
                {sendingStatus ? (
                  <CircularProgress size={24} className="submit-loader" />
                ) : (
                  lang[props.language]['saveChanges']
                )}
              </Button>
            </div>
          </div>
        </Backdrop>
      </div>
    </Modal>
  );
};

EditInfoModal.propTypes = {
  language: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  event: PropTypes.object,
  getUser: PropTypes.func,
  openModal: PropTypes.func.isRequired,
  updateUser: PropTypes.func,
  closeModal: PropTypes.func.isRequired,
  policy: PropTypes.object.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    policy: state.api.policy.data,
    language: state.api.language.selected,
    event: state.api.event.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getUser: () => dispatch(getUser()),
  updateUser: (eventId, policyId, userId, data) =>
    dispatch(updateUser(eventId, policyId, userId, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditInfoModal);
