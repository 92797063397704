import React from 'react';
import PropTypes from 'prop-types';
import InfoFields from './partials/InfoFields';
import Products from './partials/Products';
import Payment from './partials/Payment';
import KMBLoader from '@components/layout/KMBLoader';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepperConnector from '../../layout/StepperConnector';
import StepperIcon from '../../layout/StepperIcon';
import PlanSelection from './partials/PlanSelection';
import CompleteRegistration from './partials/CompleteRegistration';
import { PrimaryButton } from '../../layout/Buttons';
import Loader from '@components/layout/Loader';

const queryString = require('query-string');

export default class SignupPageNew extends React.Component {
  constructor(props) {
    super(props);
    this.query = queryString.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    this.setTotalProductCost = this.setTotalProductCost.bind(this);
    this.setUserFields = this.setUserFields.bind(this);
    this.setSubscription = this.setSubscription.bind(this);
    this.setProducts = this.setProducts.bind(this);
    this.setNoProducts = this.setNoProducts.bind(this);
    this.getSteps = this.getSteps.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.completeOrValidateParticipant =
      this.completeOrValidateParticipant.bind(this);

    this.state = {
      availableProducts: [],
      totalProdCost: 0,
      userFields: {},
      subscriptionInfo: {},
      orgCrmUserId: null,
      knownUserFields: null,
      failedTokenError: '',
      noProducts: false,
      activeStep: 0,
      width: window.innerWidth,
      height: window.innerHeight,
      steps: [],
      invalidToken: false,
    };
  }

  getSteps(user) {
    const hasMandatoryPayment = this.props.policy.mandatoryPayment;
    const hasProducts = this.props.products.length > 0;
    // const userIsSponsored = user.info.sponsored;
    const steps = [
      {
        key: 'info-fields',
        value: window.lang[this.props.language].generalInfoRegistration,
      },
    ];
    //case self registration
    if (hasMandatoryPayment) {
      // case prompt payment for registration
      if (hasProducts && !user.active) {
        // case products are available online
        steps.push(
          {
            key: 'products',
            value: window.lang[this.props.language].additionalProductsBenefits,
          },
          {
            key: 'checkout',
            value: window.lang[this.props.language].checkOutPayment,
          }
        );
      } else {
        // case products are NOT available online
        steps.push({
          key: 'checkout',
          value: window.lang[this.props.language].checkOutPayment,
        });
      }
    } else {
      // case there is no prompt for payment
      steps.push({
        key: 'complete',
        value: window.lang[this.props.language].completeRegistration,
      });
    }
    return steps;
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateWindowDimensions);
    this.props
      .validateToken(this.query.t)
      .then((user) => {
        const newState = { knownUserFields: user.data.info };
        if (
          this.props.policy.type === 'private' &&
          this.props.policy.saveUsersToCrm
        ) {
          newState.orgCrmUserId = user.data.orgCrmUserId || user.data.id;
        }
        newState.steps = this.getSteps(user.data);
        this.setState(newState);
      })
      .catch((err) => this.setState({ failedTokenError: err.message }));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  handleNext = () => {
    this.setState({ activeStep: this.state.activeStep + 1 });
  };

  handleBack = () => {
    let newStepIndex = this.state.activeStep - 1;

    if (this.state.availableProducts?.length === 0) {
      newStepIndex = 0;
    }

    if (newStepIndex > 0 && this.state.steps[newStepIndex].key === 'products') {
      if (!this.userIsSponsored && !this.userIsRegistered) {
        this.fetchAndCalculateProducts(this.state.userFields, this.query.t);
      }
    }
    this.setState({ activeStep: newStepIndex });
  };

  handleReset = () => {
    this.setState({ activeStep: 0 });
  };

  setUserFields(fields) {
    this.setState({ userFields: fields });
  }

  setProducts(prods) {
    this.setState({ availableProducts: prods });
  }

  setNoProducts() {
    this.setState({ noProducts: true });
  }

  setTotalProductCost(prodCost) {
    this.setState({ totalProdCost: prodCost });
  }

  setSubscription(data) {
    this.setState({ subscriptionInfo: data });
  }

  parseAndFormatProducts(prodArr) {
    if (
      this.props.products?.length &&
      this.props.products?.[0]?._passedThroughTranslator
    ) {
      this.setState({ products: this.props.products });
      return;
    }

    const products = [];
    prodArr.map((product) => {
      product.prices.map((price) => {
        const uniquePrice = {};

        uniquePrice['id'] = price.id;
        uniquePrice['productId'] = product.id;
        uniquePrice['name'] = `${product.name} - ${price.name}`;
        uniquePrice['description'] = product.description;
        uniquePrice['quantity'] = 0;
        uniquePrice['type'] = 'product';
        uniquePrice['price'] = `${price.sellingPrice} ${this.props.currency}`;
        uniquePrice['totalPrice'] = `0 ${this.props.currency}`;
        uniquePrice['availableQuantity'] = Math.min(
          product.availableQuantity,
          product.remainingOnlineQuantity,
          product.availableOnlineQuantity
        );

        const maxQuantity = Math.min(
          product.maxQuantityPerUser,
          product.availableOnlineQuantity,
          product.remainingOnlineQuantity,
          product.availableQuantity
        );

        uniquePrice['maxQuantity'] = maxQuantity;
        uniquePrice['availablePaymentMethodsKeys'] = (() => {
          const returnValue = [];
          if (
            product.availablePaymentMethods.includes('card') &&
            (this.props.policy.billing.creditCard ||
              this.props.policy.billing.paypal)
          )
            returnValue.push('card');
          if (
            product.availablePaymentMethods.includes('bank') &&
            this.props.policy.billing.bank
          )
            returnValue.push('bank');
          return returnValue;
        })();
        uniquePrice['availablePaymentMethods'] = (() => {
          if (product.availablePaymentMethods.length > 0) {
            return Object.values(product.availablePaymentMethods)
              .map((v) => {
                if (v === 'bank') {
                  return window.lang[this.props.language]['bankTransfer'];
                } else if (v === 'card') {
                  return window.lang[this.props.language]['creditOrDebitCard'];
                } else {
                  return null;
                }
              })
              .join('/');
          } else {
            return '-';
          }
        })();

        uniquePrice['_passedThroughTranslator'] = true;

        products.push(uniquePrice);
      });
    });

    if (products.length == 0) {
      this.setNoProducts();
    }
    return products;
  }

  returnStepper() {
    const stepperOrientation =
      this.state.width > 834 ? 'horizontal' : 'vertical';
    return (
      <Stepper
        className={`${stepperOrientation}-stepper`}
        style={{ padding: '6px' }}
        connector={<StepperConnector orientation={`${stepperOrientation}`} />}
        activeStep={this.state.activeStep}
        orientation={`${stepperOrientation}`}
        alternativeLabel={stepperOrientation === 'horizontal' ? true : false}>
        {this.state.steps.map(({ value: label }) => {
          const stepProps = {};
          const labelProps = {};

          return (
            <Step key={label} {...stepProps}>
              <StepLabel
                className={'color-label'}
                StepIconComponent={(stepProps) => (
                  <StepperIcon {...stepProps} />
                )}
                {...labelProps}>
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    );
  }

  completeOrValidateParticipant() {
    this.loading = true;
    this.props
      .registerUser(
        this.state.userFields,
        this.query.t,
        this.state.orgCrmUserId,
        ['registration']
      )
      .then((res) => {
        if (res) {
          this.loading = false;
          window.location.replace(location.href.split('?')[0]);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }

  fetchAndCalculateProducts(fields, token) {
    const quantity_persistence = [];
    if (
      this.state.availableProducts?.length &&
      this.state.availableProducts[0]?._passedThroughTranslator
    ) {
      this.state.availableProducts.forEach((productPrice) => {
        if (productPrice.quantity > 0) {
          quantity_persistence.push({
            quantity: productPrice.quantity,
            priceId: productPrice.id,
            productId: productPrice.productId,
          });
        }
      });
    }
    this.setState({ fetchingSubscription: true });
    return this.props.calculateSubscription(fields, token).then(({ data }) => {
      if (!data.availableProducts.length) data.availableProducts = [];

      data.availableProducts = this.parseAndFormatProducts(
        data.availableProducts
      );

      const stateAwareProducts = [];
      data.availableProducts.forEach((newProduct) => {
        let stateMapped = false;
        quantity_persistence.forEach((oldProduct) => {
          if (
            oldProduct.priceId == newProduct.id &&
            oldProduct.productId == newProduct.productId
          ) {
            stateMapped = true;
            newProduct.quantity = oldProduct.quantity;

            stateAwareProducts.push(newProduct);
          }
        });
        if (stateMapped) return;
        stateAwareProducts.push(newProduct);
      });

      this.setState({
        fetchingSubscription: false,
        subscriptionInfo: data,
        availableProducts: stateAwareProducts,
      });
      return data;
    });
  }

  returnCorrectStep() {
    switch (this.state.steps[this.state.activeStep]?.key) {
      case 'validate': {
        return (
          <CompleteRegistration
            buttonText={window.lang[this.props.language].validateRegistration}
            onSubmit={this.completeOrValidateParticipant}
            message={
              window.lang[this.props.language].validateRegistrationMessage
            }
            policy={this.props.policy}
            language={this.props.language}
            handlePrev={this.handleBack}
            changeSection={this.props.changeSection}
            prevStep={this.state.steps[this.state.activeStep - 1]?.value}
          />
        );
      }
      case 'complete': {
        return (
          <CompleteRegistration
            buttonText={window.lang[this.props.language].completeRegistration}
            onSubmit={this.completeOrValidateParticipant}
            message={
              window.lang[this.props.language].completeRegistrationMessage
            }
            policy={this.props.policy}
            language={this.props.language}
            handlePrev={this.handleBack}
            changeSection={this.props.changeSection}
            prevStep={this.state.steps[this.state.activeStep - 1]?.value}
          />
        );
      }
      case 'select-plan':
        return (
          <React.Fragment>
            <PlanSelection
              language={this.props.language}
              policy={this.props.policy}
              knownUserFields={this.state.knownUserFields}
              setUserFields={this.setUserFields}
              setSubscription={this.setSubscription}
              userFields={this.state.userFields}
              registerUser={this.props.registerUser}
              handleNext={this.handleNext}
              handlePrev={this.handleBack}
            />
          </React.Fragment>
        );
      case 'info-fields':
        return (
          <React.Fragment>
            {this.state.knownUserFields ? (
              <InfoFields
                language={this.props.language}
                policy={this.props.policy}
                nextStep={this.state.steps[this.state.activeStep + 1]?.value}
                prevStep={this.state.steps[this.state.activeStep - 1]?.value}
                calculateSubscription={(fields, token) => {
                  return this.fetchAndCalculateProducts(fields, token);
                }}
                knownUserFields={this.state.knownUserFields}
                setUserFields={this.setUserFields}
                setSubscription={this.setSubscription}
                userFields={this.state.userFields}
                registerUser={this.props.registerUser}
                handleNext={this.handleNext}
                handlePrev={this.handleBack}
                changeSection={this.props.changeSection}
              />
            ) : (
              <div style={{ width: '100%' }}>
                <KMBLoader rows={10} />
              </div>
            )}
          </React.Fragment>
        );
      case 'products':
        return (
          <Products
            subscriptionInfo={this.state.subscriptionInfo}
            user={this.props.user}
            language={this.props.language}
            products={this.state.availableProducts}
            setProducts={this.setProducts}
            nextStep={this.state.steps[this.state.activeStep + 1]?.value}
            prevStep={this.state.steps[this.state.activeStep - 1]?.value}
            currency={this.props.currency}
            setTotalProductCost={this.setTotalProductCost}
            changeSection={this.props.changeSection}
            setNoProducts={this.setNoProducts}
            policy={this.props.policy}
            registerUser={this.props.registerUser}
            knownUserFields={this.state.knownUserFields}
            fields={this.state.userFields}
            orgCrmUserId={this.state.orgCrmUserId}
            handleNext={this.handleNext}
            handlePrev={this.handleBack}
          />
        );
      case 'checkout':
        return (
          <React.Fragment>
            {JSON.stringify(this.state.subscriptionInfo) === '{}' ? (
              <div style={{ width: '100%' }}>
                <KMBLoader rows={10} />
              </div>
            ) : (
              <Payment
                products={this.state.availableProducts}
                policy={this.props.policy}
                registerUser={this.props.registerUser}
                paypalCreateOrder={this.props.paypalCreateOrder}
                eventId={this.props.eventId}
                currencyIso={this.props.currencyIso}
                submitPayment={this.props.submitPayment}
                paypalTransactionCompleted={
                  this.props.paypalTransactionCompleted
                }
                changeSection={this.props.changeSection}
                fields={this.state.userFields}
                knownUserFields={this.state.knownUserFields}
                language={this.props.language}
                orgCrmUserId={this.state.orgCrmUserId}
                totalProdCost={this.state.totalProdCost}
                subscriptionInfo={this.state.subscriptionInfo}
                currency={this.props.currency}
                getReceipt={this.props.getReceipt}
                goToBilling={this.props.goToBilling}
                handlePrev={this.handleBack}
                validateToken={this.props.validateToken}
                prevStep={this.state.steps[this.state.activeStep - 1]?.value}
              />
            )}
          </React.Fragment>
        );
    }
  }
  returnError() {
    return (
      <div className="error-msg">
        <div>{this.state.failedTokenError}</div>
        <div className="btn-container">
          <button
            onClick={() => {
              this.props.changeSection('login');

              this.props.changeScreen('login');
            }}>
            BACK
          </button>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.failedTokenError) {
      return (
        <div className="invalid-token">
          <h3>Expired or invalid token</h3>
          <p>Please contact the administration for more information</p>
          <div className="warning-message">
            <div className="warning-icon-wrapper">
              <ReportProblemOutlinedIcon className="warning-icon" />
            </div>
            <div className="warning-message-text">
              {this.state.failedTokenError}
            </div>
          </div>
          <PrimaryButton
            onClick={() =>
              window.location.replace(location.href.split('?')[0])
            }>
            OK
          </PrimaryButton>
        </div>
      );
    }
    if (!this.state.steps.length || this.state.fetchingSubscription) {
      return <Loader />;
    }
    return (
      <React.Fragment>
        {this.returnStepper()}
        <div className="step-container">
          <h2 className="section-title">
            {this.state.steps[this.state.activeStep]?.value}
          </h2>
          <div className="step-card">
            {this.state.failedTokenError === ''
              ? this.returnCorrectStep()
              : this.returnError()}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

SignupPageNew.defaultProps = {
  getReceipt: () => {},
};

SignupPageNew.propTypes = {
  changeScreen: PropTypes.func,
  changeSection: PropTypes.func,
  language: PropTypes.string,
  policy: PropTypes.object,
  products: PropTypes.array,
  user: PropTypes.object,
  calculateSubscription: PropTypes.func,
  currency: PropTypes.string,
  validateToken: PropTypes.func,
  registerUser: PropTypes.func.isRequired,
  paypalCreateOrder: PropTypes.func,
  eventId: PropTypes.number,
  currencyIso: PropTypes.string,
  submitPayment: PropTypes.func,
  paypalTransactionCompleted: PropTypes.func,
  getProducts: PropTypes.func,
  getReceipt: PropTypes.func,
  goToBilling: PropTypes.func,
};
