import React from 'react';
import PropTypes from 'prop-types';
import PriceRow from './partials/PriceRow';

export default class PriceModal extends React.Component {
  constructor(props) {
    super(props);
    this.onRender = this.onRender.bind(this);

    this.columns = {
      id: {
        name: window.lang[this.props.language]['id'],
      },
      name: {
        name: window.lang[this.props.language]['name'],
      },
      sellingPrice: {
        name: window.lang[this.props.language]['price'],
      },
    };
  }
  onRender(col, item) {
    switch (col.key) {
      case 'id':
        return <span>{item.id}</span>;
      case 'quantity':
        return <span>{item.quantity || 1}</span>;
      case 'paidAmount':
        return (
          <span>{`${parseFloat(item.paidAmount).toFixed(2)} ${
            this.props.currency
          }`}</span>
        );
    }
  }
  render() {
    const { prices, color, currency } = this.props;
    const pricesList = [];
    prices.map((price) => {
      pricesList.push(<PriceRow color={color} price={price} />);
    });
    return (
      <div>
        <div className="prices-modal">
          <span
            className="icon-close"
            onClick={() => this.props.changeModalStatus()}
          />
          <div className="prices-container ">
            <div className="header">
              <div className="entry-id"> Id</div>
              <div className="entry-name"> Name</div>
              <div className="entry-price"> Price ({currency})</div>
              {/* <div className="entry-quant"> Quantity</div> */}
            </div>
            {pricesList}
          </div>
          <div className="add-btn">
            {window.lang[this.props.language]['add']}
          </div>
        </div>
      </div>
    );
  }
}

PriceModal.propTypes = {
  prices: PropTypes.array,
  changeModalStatus: PropTypes.func,
  language: PropTypes.string,
  color: PropTypes.string,
  currency: PropTypes.string,
};
