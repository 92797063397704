import React from 'react';
import PropTypes from 'prop-types';
import KMBLoader from '@components/layout/KMBLoader';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const LightTooltip = withStyles(() => ({
  tooltip: {
    fontSize: 13,
    letterSpacing: 0.9,
  },
}))(Tooltip);

export default class SingleProduct extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { product, modalIsOpen, color, currency } = this.props;
    if (this.props.loading) {
      return (
        <div className="card">
          <KMBLoader rows={10} />
        </div>
      );
    } else {
      return (
        <div className="card">
          {product.description && (
            <LightTooltip title={product.description} placement="bottom-end">
              <div className="tooltip-desc">i</div>
            </LightTooltip>
          )}
          <div className="product-name">{product.name}</div>
          <div className="start-from">
            {window.lang[this.props.language]['startingFrom']}
            <div className="price">
              {this.props.minPrice}
              {currency}
            </div>
          </div>
          <button
            onClick={() => this.props.setProductPrices(product.prices)}
            disabled={modalIsOpen}
            style={
              modalIsOpen
                ? { cursor: 'auto', backgroundColor: color }
                : { cursor: 'pointer', backgroundColor: color }
            }>
            {window.lang[this.props.language]['view']}
          </button>
        </div>
      );
    }
  }
}

SingleProduct.propTypes = {
  loading: PropTypes.bool,
  product: PropTypes.object,
  language: PropTypes.string,
  modalIsOpen: PropTypes.bool,
  setProductPrices: PropTypes.func,
  color: PropTypes.string,
  minPrice: PropTypes.number,
  currency: PropTypes.string,
};
