import React from 'react';
import PropTypes from 'prop-types';

export default class PriceRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pricesToBuy: {},
    };
  }
  addRemoveProduct(item, type) {
    const newPrices = { ...this.state.pricesToBuy };
    if (!newPrices[item.id]) {
      newPrices[item.id] = 0;
    }
    if (type === 'remove' && newPrices[item.id] === 0) {
      return;
    }
    if (type === 'add') {
      newPrices[item.id]++;
    } else {
      newPrices[item.id]--;
    }
    this.setState({ pricesToBuy: newPrices });
  }
  render() {
    const { price, color } = this.props;

    return (
      <div className="price">
        <div className="entry-id">{price.id}</div>
        <div className="entry-name">{price.name}</div>
        <div className="entry-price" style={{ paddingLeft: 2 }}>
          {price.sellingPrice}
        </div>
        <div className="entry-quant">
          <div
            onClick={() => {
              this.addRemoveProduct(price, 'remove');
            }}
            className="product-quantity minus">
            -
          </div>
          <div className="quantity">
            {this.state.pricesToBuy[price.id] || 0}
          </div>
          <div
            onClick={() => this.addRemoveProduct(price, 'add')}
            className="product-quantity">
            +
          </div>
        </div>
        <div className="add" style={{ backgroundColor: color }}>
          <div className="icon-cart" />
        </div>
      </div>
    );
  }
}

PriceRow.propTypes = {
  price: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
};
