import React from 'react';
import Table from '@components/layout/Table';
import Collapsible from 'react-collapsible';
import moment from 'moment';
import { saveByteArray } from '@helpers';
import SuccessPage from '@components/layout/SuccessPage';
import InfoPage from '@components/layout/InfoPage';
import ErrorPage from '@components/layout/ErrorPage';
import Select from '@components/layout/Select2';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const queryString = require('query-string');

export default class Payments extends React.Component {
  constructor(props) {
    super(props);
    this.onRender = this.onRender.bind(this);
    this.onRenderSubscriptions = this.onRenderSubscriptions.bind(this);
    this.onTrClick = this.onTrClick.bind(this);
    this.getReceipt = this.getReceipt.bind(this);
    this.query = queryString.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });

    this.columns = {
      id: {
        name: window.lang[this.props.language]['id'],
      },
      type: {
        name: window.lang[this.props.language]['type'],
      },
      way: {
        name: window.lang[this.props.language]['way'],
      },
      paidAmount: {
        name: window.lang[this.props.language]['amount'],
      },
      status: {
        name: window.lang[this.props.language]['status'],
      },
      action: {
        name: '',
      },
    };

    this.subscriptionColumns = {
      cosmetic: {
        name: '',
      },
      id: {
        name: window.lang[this.props.language]['id'],
      },
      typeName: {
        name: window.lang[this.props.language]['type'],
      },
      periodName: {
        name: window.lang[this.props.language]['period'],
      },
      startDate: {
        name: window.lang[this.props.language]['startDate'],
      },
      endDate: {
        name: window.lang[this.props.language]['endDate'],
      },
      price: {
        name: window.lang[this.props.language]['price'],
      },
    };
    this.paymentOptions = {};

    if (this.props.billing.bank) {
      this.paymentOptions.bank =
        window.lang[this.props.language]['bankTransfer'];
    }
    if (this.props.billing.creditCard || this.props.billing.paypal) {
      this.paymentOptions.cc = window.lang[this.props.language]['creditCard'];
    }
    this.state = {
      paymentRef: null,
      orderId: null,
      payments: [],
      products: {},
      subscriptions: [],
      agreeToTerms: false,
      paymentSelected: this.paymentOptions.cc
        ? 'cc'
        : this.paymentOptions.bank
        ? 'bank'
        : '',
    };
  }

  componentDidUpdate(nextProps) {
    if (JSON.stringify(nextProps) !== JSON.stringify(this.props)) {
      this.UNSAFE_componentWillMount();
    }
  }

  UNSAFE_componentWillMount() {
    const { accomodation, subscription, eventId, eventPolicyId, products } =
      this.props.user;

    if (this.query.status === 'CAPTURED' && this.query.tk) {
      this.props
        .validateToken(this.query.tk)
        .then((d) => {
          this.setState({
            paymentRef: d.data.paymentRef,
            orderId: d.data.orderId,
          });
        })
        .catch((e) => console.error(e));
    }

    this.props.getSubscriptions(eventId, eventPolicyId).then((d) => {
      const subscriptions = d.data.map((s) => ({
        id: s.id,
        typeName: s.typeName,
        periodName: s.periodName,
        startDate: moment(s.startDate.tz).format('DD-MM-Y HH:mm'),
        endDate: moment(s.endDate.tz).format('DD-MM-Y HH:mm'),
        price: `${s.price} ${this.props.currency}`,
        timezone: s.startDate.timezone,
      }));

      const timezone = (subscriptions[0] || {}).timezone;
      if (!this.subscriptionColumns.startDate.name.includes(timezone)) {
        this.subscriptionColumns.startDate.name += ` (${timezone})`;
        this.subscriptionColumns.endDate.name += ` (${timezone})`;
      }

      this.setState({ subscriptions });
    });

    const accomodationPayment = accomodation?.payments;
    const accomodationSponsorship = accomodation?.sponsorships;
    const subscriptionPayment = subscription?.payment;
    const subscriptionSponsorship = subscription?.sponsorship;

    const payments = [];

    this.props.user.payments?.map((p) => {
      if (p.amount === 0) {
        return true;
      }
      payments.push({
        id: p.id,
        type: p.type,
        transactionId: p.transactionId === '' ? 'N/A' : p.transactionId,
        way: p.way,
        price: `${
          p.type === 'accomodation'
            ? accomodation.price
            : subscription.eventSubscriptionPrice
        } ${this.props.currency}`,
        dueAmount: `${
          p.type === 'accomodation'
            ? Number((accomodation.price - p.sumPaid).toFixed(2))
            : Number(
                (subscription.eventSubscriptionPrice - p.sumPaid).toFixed(2)
              )
        } ${this.props.currency}`,
        paidAmount: `${Number(p.amount.toFixed(2))} ${this.props.currency}`,
        status: 'Completed',
      });
    });

    products?.map((product) => {
      let productName = product.name.split(' ');
      productName.splice(0, 1);
      productName = productName.join(' ');
      if (product.dueAmount > 0) {
        payments.push({
          referenceName: 'productpurchase_' + product.id,
          id: product.id,
          transactionId: 'N/A',
          type: productName,
          checkAvailabilityName: 'product_' + product.eventProductId,
          way: 'N/A',
          paidAmount: `${product.dueAmount} ${this.props.currency}`,
          status: 'Pending',
        });
      }
    });
    // check accomodation
    if (accomodation && Object.keys(accomodation).length > 0) {
      //case there is accomodation

      //case accomodation is sponsored
      if (Object.keys(accomodationSponsorship).length > 0) {
        //case when accomodation is sponsored
        payments.unshift({
          referenceName: 'accomodation',
          id: accomodation.sponsorships.id,
          transactionId: 'N/A',
          type: 'Accomodation',
          way: `Sponsord By ${accomodation.sponsorships.orgSponsorName}`,
          paidAmount: `${accomodation.price} ${this.props.currency}`,
          status: 'Completed',
        });
      } else if (Object.keys(accomodationPayment).length > 0) {
        // case payment is partially paid
        if (accomodationPayment.dueAmount > 0) {
          payments.unshift({
            referenceName: 'accomodation',
            id: 'N/A',
            way: 'N/A',
            type: 'Accomodation',
            transactionId: 'N/A',
            paidAmount: `${accomodationPayment.dueAmount} ${this.props.currency}`,
            status: 'Pending',
          });
        }
      } else if (accomodation.price > 0) {
        // case payment is not paid at all and over zero

        payments.unshift({
          referenceName: 'accomodation',
          id: 'N/A',
          way: 'N/A',
          type: 'Accomodation',
          transactionId: 'N/A',
          paidAmount: `${accomodation.price} ${this.props.currency}`,
          status: 'Pending',
        });
      }
    }

    // check registration
    if (
      Object.keys(subscription).length > 0 &&
      (this.props.user.info.sponsored == 0 || !this.props.user.info.sponsored)
    ) {
      //case when subscription exists

      //case subscription is sponsored
      if (Object.keys(subscriptionSponsorship).length > 0) {
        // case when subscription is sponsored
        payments.unshift({
          referenceName: 'registration',
          id: subscription.sponsorship.id,
          transactionId: 'N/A',
          type: 'Registration',
          way: `Sponsord By ${subscription.sponsorship.orgSponsorName}`,
          paidAmount: `${subscription.eventSubscriptionPrice} ${this.props.currency}`,
          status: 'Completed',
        });
      } else if (Object.keys(subscriptionPayment).length > 0) {
        // case when subscription is partially paid
        if (this.props.user.subscriptionDueAmount > 0) {
          payments.unshift({
            referenceName: 'registration',
            id: 'N/A',
            way: 'N/A',
            type: 'Registration',
            transactionId: 'N/A',
            paidAmount: `${this.props.user.subscriptionDueAmount} ${this.props.currency}`,
            status: 'Pending',
          });
        }
      } else if (this.props.user.subscriptionPrice > 0) {
        // case when subscription not paid at all
        payments.unshift({
          referenceName: 'registration',
          id: 'N/A',
          way: 'N/A',
          type: 'Registration',
          transactionId: 'N/A',
          paidAmount: `${this.props.user.subscriptionPrice} ${this.props.currency}`,
          status: 'Pending',
        });
      }
    }
    this.setState({ payments });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  addRemoveProduct(item) {
    const { products } = this.state;
    const exists = products.hasOwnProperty(item.referenceName);
    if (exists) {
      delete products[item.referenceName];
      this.setState({ products });
    } else {
      products[item.referenceName] = item;
      this.setState({ products });
    }
  }

  getReceipt(id, token) {
    return this.props
      .getReceipt(this.props.user.eventId, id, this.state.paymentRef, token)
      .then((buffer) => {
        const reportName = `receipt_${
          id ? id : 'ref' + this.state.paymentRef
        }.pdf`;
        saveByteArray(reportName, buffer);
      })
      .catch((e) => console.error(e));
  }

  onRenderSubscriptions(col, item) {
    switch (col.key) {
      case 'cosmetic':
        if (item.id === this.props.user.subscription.eventSubscriptionId) {
          return <i className="icon-check" aria-hidden="true" />;
        }
        break;
      case 'price':
        return (
          <span>
            {`${parseFloat(item.price).toFixed(2)} ${this.props.currency}`}
          </span>
        );
    }
  }
  onTrClick(item) {
    if (item.status === 'Pending') {
      const el = document.getElementById(
        `payments-checkbox-${item.type}-${item.id}`
      );
      el.checked = !el.checked;
      this.addRemoveProduct(item);
    }
  }

  onRender(col, item, viewOnly) {
    switch (col.key) {
      case 'status':
        return (
          <span
            className={
              item.status == 'Pending' ? 'color-warning' : 'color-success'
            }>
            {item.status}
          </span>
        );
      case 'paidAmount':
        return (
          <span>
            {`${parseFloat(item.paidAmount).toFixed(2)} ${this.props.currency}`}
          </span>
        );
      case 'action':
        return (this.props.billing.creditCard === 1 ||
          this.props.billing.paypal === 1) &&
          this.props.billing.cardOptions.includes(
            item.checkAvailabilityName || item.referenceName
          ) &&
          item.status !== 'Completed' &&
          this.props.counter === null &&
          !viewOnly ? (
          <input
            style={{ transform: 'scale(1.5)', pointerEvents: 'none' }}
            type="checkbox"
            id={`payments-checkbox-${item.type}-${item.id}`}
          />
        ) : (item.way === 'card' || item.way === 'paypal') &&
          item.status === 'Completed' ? (
          <span>
            <p
              className="receipt"
              onClick={() => this.getReceipt(item.id, undefined)}
              style={{ fontSize: '13px', color: '#02A9F4' }}>
              {window.lang[this.props.language]['receipt']}
            </p>
          </span>
        ) : (
          ''
        );
    }
  }

  render() {
    if (this.query.status === 'CAPTURED') {
      return (
        <SuccessPage
          goToBilling={this.props.goToBilling}
          language={this.props.language}
          orderId={this.state.orderId}
          paymentRef={this.state.paymentRef}
          getReceipt={() => this.getReceipt(undefined, this.query.tk)}
        />
      );
    } else if (this.query.status === 'REFUSED') {
      return (
        <InfoPage
          goToBilling={this.props.goToBilling}
          language={this.props.language}
        />
      );
    } else if (this.query.status === 'ERROR') {
      return (
        <ErrorPage
          goToBilling={this.props.goToBilling}
          language={this.props.language}
        />
      );
    } else if (this.props.calledFromAccount === true) {
      const owes =
        this.state.payments.filter((p) => p.status === 'Pending').length > 0;
      return (
        <div className="component-billing">
          {owes ? (
            <React.Fragment>
              <div className="payment-title">
                {window.lang[this.props.language]['paymentTitle']}
              </div>
              <div className="payments-container">
                <div className="payment-subtitle">
                  {window.lang[this.props.language]['payDue']}
                </div>
                <div className="payment-option">
                  <Select
                    name="paymentTypes"
                    onChange={(e) => {
                      if (e === 'bank') this.setState({ products: {} });
                      this.setState({ paymentSelected: e });
                    }}
                    className="form-control"
                    value={this.state.paymentSelected}
                    options={this.paymentOptions}
                  />
                </div>
              </div>

              {this.state.paymentSelected === 'bank' && (
                <div>
                  <h5>{window.lang[this.props.language]['bankInfo']}</h5>
                  <p>
                    {window.lang[this.props.language]['iban']}:{' '}
                    {this.props.billing.iban}
                  </p>
                  <p>{this.props.billing.bankInfo}</p>
                  <p style={{ fontSize: '13px' }}>
                    {window.lang[this.props.language]['bankMessage']}
                  </p>
                  {(this.props.supportEmail || '').length > 0 && (
                    <p style={{ fontSize: '13px' }}>
                      {
                        window.lang[this.props.language][
                          'paymentsContactUsMessage'
                        ]
                      }{' '}
                      <a
                        href={`mailto:${this.props.supportEmail}?Subject=Payment%20Support%20-%20${this.props.event.name}`}
                        target="_blank"
                        rel="noreferrer">
                        {this.props.supportEmail}
                      </a>
                    </p>
                  )}
                </div>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment />
          )}
          {this.state.paymentSelected === 'cc' && (
            <div>
              {this.state.payments.filter((p) => p.status === 'Pending')
                .length > 0 && (
                <React.Fragment>
                  <p>{this.props.billing.creditCardInfo}</p>
                  <p style={{ fontSize: '13px' }}>
                    {window.lang[this.props.language]['selectProducts']}
                  </p>
                </React.Fragment>
              )}
              {(this.props.supportEmail || '').length > 0 && (
                <p style={{ fontSize: '13px' }}>
                  {window.lang[this.props.language]['contactUsMessage']}{' '}
                  <a
                    href={`mailto:${this.props.supportEmail}?Subject=Payment%20Support%20-%20${this.props.event.name}`}
                    target="_blank"
                    rel="noreferrer">
                    {this.props.supportEmail}
                  </a>
                </p>
              )}
              {this.state.payments.filter((p) => p.status === 'Pending')
                .length !== 0 ? (
                <Table
                  id={'pending-Payments'}
                  columns={this.columns}
                  items={this.state.payments.filter(
                    (p) => p.status === 'Pending'
                  )}
                  onRender={(col, item) => this.onRender(col, item, 0)}
                  onTrClick={this.onTrClick}
                />
              ) : (
                <div className="empty-results payments-table">
                  <span className="icon-check big" />

                  <p>{window.lang[this.props.language]['noPendingPayments']}</p>
                </div>
              )}
              {Object.keys(this.state.products).length !== 0 && (
                <div
                  style={{
                    padding: 10,
                    background: 'white',
                    marginTop: -20,
                  }}>
                  <div
                    style={{
                      background: '#F7F7F7',
                      padding: 10,
                      textAlign: 'center',
                    }}>
                    <Button
                      className="submit-payment"
                      id="submit-payment"
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        this.props.showPayment(this.state.products);
                      }}>
                      <Typography style={{ color: 'white' }}>
                        {window.lang[this.props.language]['proceedPayment']}
                      </Typography>
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
          {this.state.subscriptions.length > 0 && (
            <div>
              <Collapsible
                trigger={
                  <h5 className="collapsible-title">
                    <span className="icon-arrow-right" />
                    {'\t'}
                    {
                      window.lang[this.props.language][
                        'paymentsRegistrationFee'
                      ]
                    }
                  </h5>
                }
                transitionTime={100}>
                <p style={{ fontSize: '13px' }}>
                  {
                    window.lang[this.props.language][
                      'paymentsRegistrationFeeMessage'
                    ]
                  }
                  <span
                    style={{
                      color: '#02A9F4',
                      fontSize: '14px',
                      cursor: 'pointer',
                    }}
                    onClick={() => this.props.changeSection('account')}>
                    {' '}
                    {window.lang[this.props.language]['here']}.
                  </span>{' '}
                  {
                    window.lang[this.props.language][
                      'paymentsRegistrationFeeViewMessage'
                    ]
                  }
                </p>

                {(this.props.supportEmail || '').length > 0 && (
                  <p style={{ fontSize: '13px' }}>
                    {
                      window.lang[this.props.language][
                        'contactUsPaymentsRegistrationMessage'
                      ]
                    }{' '}
                    <a
                      href={`mailto:${this.props.supportEmail}?Subject=Registration-Fees%20Support%20-%20${this.props.event.name}`}
                      target="_blank"
                      rel="noreferrer">
                      {this.props.supportEmail}
                    </a>
                  </p>
                )}
                <Table
                  id={'list-Subscriptions'}
                  columns={this.subscriptionColumns}
                  items={this.state.subscriptions}
                  onRender={this.onRenderSubscriptions}
                />
              </Collapsible>
            </div>
          )}
        </div>
      );
    } else {
      const owes =
        this.state.payments.filter((p) => p.status === 'Pending').length > 0;
      this.props.checkIfOwe && this.props.checkIfOwe(owes);
      return (
        <div style={owes ? { marginTop: -40 } : {}}>
          <div className="component-billing-payments">
            {owes ? (
              <React.Fragment>
                <div className="payment-title">
                  {window.lang[this.props.language]['paymentTitle']}
                </div>
                <div className="payments-container">
                  <div className="payment-subtitle">
                    {window.lang[this.props.language]['payDue']}
                  </div>
                  <div className="payment-option">
                    <Select
                      name="paymentTypes"
                      onChange={(e) => {
                        this.setState({ paymentSelected: e });
                        if (e === 'bank') this.setState({ products: {} });
                      }}
                      className="form-control"
                      value={this.state.paymentSelected}
                      options={this.paymentOptions}
                    />
                  </div>
                </div>

                {this.state.paymentSelected === 'bank' && (
                  <div>
                    <h5>{window.lang[this.props.language]['bankInfo']}</h5>
                    <p>
                      {window.lang[this.props.language]['iban']}:{' '}
                      {this.props.billing.iban}
                    </p>
                    <p>{this.props.billing.bankInfo}</p>
                    <p style={{ fontSize: '13px' }}>
                      {window.lang[this.props.language]['bankMessage']}
                    </p>
                    {(this.props.supportEmail || '').length > 0 && (
                      <p style={{ fontSize: '13px' }}>
                        {
                          window.lang[this.props.language][
                            'paymentsContactUsMessage'
                          ]
                        }{' '}
                        <a
                          href={`mailto:${this.props.supportEmail}?Subject=Payment%20Support%20-%20${this.props.event.name}`}
                          target="_blank"
                          rel="noreferrer">
                          {this.props.supportEmail}
                        </a>
                      </p>
                    )}
                  </div>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment />
            )}
            {this.state.paymentSelected === 'cc' && (
              <div>
                {this.state.payments.filter((p) => p.status === 'Pending')
                  .length > 0 && (
                  <React.Fragment>
                    <p>{this.props.billing.creditCardInfo}</p>
                    <p style={{ fontSize: '13px' }}>
                      {window.lang[this.props.language]['selectProducts']}
                    </p>
                  </React.Fragment>
                )}
                {(this.props.supportEmail || '').length > 0 && (
                  <p style={{ fontSize: '13px' }}>
                    {window.lang[this.props.language]['contactUsMessage']}{' '}
                    <a
                      href={`mailto:${this.props.supportEmail}?Subject=Payment%20Support%20-%20${this.props.event.name}`}
                      target="_blank"
                      rel="noreferrer">
                      {this.props.supportEmail}
                    </a>
                  </p>
                )}
                {this.state.payments.filter((p) => p.status === 'Pending')
                  .length !== 0 ? (
                  <Table
                    id={'pending-Payments'}
                    columns={this.columns}
                    items={this.state.payments.filter(
                      (p) => p.status === 'Pending'
                    )}
                    onRender={(col, item) => this.onRender(col, item, 0)}
                    onTrClick={this.onTrClick}
                  />
                ) : (
                  <div className="empty-results payments-table">
                    <span className="icon-check big" />

                    <p>
                      {window.lang[this.props.language]['noPendingPayments']}
                    </p>
                  </div>
                )}
                {Object.keys(this.state.products).length !== 0 && (
                  <div
                    style={{
                      padding: 10,
                      background: 'white',
                      marginTop: -20,
                    }}>
                    <div
                      style={{
                        background: '#F7F7F7',
                        padding: 10,
                        textAlign: 'center',
                      }}>
                      <Button
                        id="submit-payment"
                        color="primary"
                        variant="contained"
                        onClick={() =>
                          this.props.showPayment(this.state.products)
                        }>
                        <Typography style={{ color: 'white' }}>
                          {window.lang[this.props.language]['proceedPayment']}
                        </Typography>
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            )}
            {this.state.subscriptions.length > 0 && (
              <div>
                <Collapsible
                  trigger={
                    <h5 className="collapsible-title">
                      <span className="icon-arrow-right" />
                      {'\t'}
                      {
                        window.lang[this.props.language][
                          'paymentsRegistrationFee'
                        ]
                      }
                    </h5>
                  }
                  transitionTime={100}>
                  <p style={{ fontSize: '13px' }}>
                    {
                      window.lang[this.props.language][
                        'paymentsRegistrationFeeMessage'
                      ]
                    }
                    <span
                      style={{
                        color: '#02A9F4',
                        fontSize: '14px',
                        cursor: 'pointer',
                      }}
                      onClick={() => this.props.changeSection('account')}>
                      {' '}
                      {window.lang[this.props.language]['here']}.
                    </span>{' '}
                    {
                      window.lang[this.props.language][
                        'paymentsRegistrationFeeViewMessage'
                      ]
                    }
                  </p>

                  {(this.props.supportEmail || '').length > 0 && (
                    <p style={{ fontSize: '13px' }}>
                      {
                        window.lang[this.props.language][
                          'contactUsPaymentsRegistrationMessage'
                        ]
                      }{' '}
                      <a
                        href={`mailto:${this.props.supportEmail}?Subject=Registration-Fees%20Support%20-%20${this.props.event.name}`}
                        target="_blank"
                        rel="noreferrer">
                        {this.props.supportEmail}
                      </a>
                    </p>
                  )}
                  <Table
                    id={'list-Subscriptions'}
                    columns={this.subscriptionColumns}
                    items={this.state.subscriptions}
                    onRender={this.onRenderSubscriptions}
                  />
                </Collapsible>
              </div>
            )}
            <Collapsible
              trigger={
                <h5 className="collapsible-title">
                  <span className="icon-arrow-right" />
                  {'\t'}
                  {window.lang[this.props.language]['paymentsViewAll']}
                </h5>
              }
              transitionTime={100}>
              <p style={{ fontSize: '13px' }}>
                {window.lang[this.props.language]['paymentsViewAllMessage']}
              </p>
              {(this.props.supportEmail || '').length > 0 && (
                <p style={{ fontSize: '13px' }}>
                  {window.lang[this.props.language]['paymentsContactUsMessage']}{' '}
                  <a
                    href={`mailto:${this.props.supportEmail}?Subject=Payment%20Support%20-%20${this.props.event.name}`}
                    target="_blank"
                    rel="noreferrer">
                    {this.props.supportEmail}
                  </a>
                </p>
              )}

              <Table
                id={'list-Payments'}
                columns={this.columns}
                items={this.state.payments}
                onRender={(col, item) => this.onRender(col, item, true)}
              />
              {this.state.payments.length === 0 && (
                <div className="empty-results payments-table">
                  <span className="icon-check big" />

                  <p>{window.lang[this.props.language]['noPendingPayments']}</p>
                </div>
              )}
            </Collapsible>
          </div>
        </div>
      );
    }
  }
}
Payments.propTypes = {
  user: PropTypes.object,
  currency: PropTypes.string,
  billing: PropTypes.object,
  validateToken: PropTypes.func,
  getReceipt: PropTypes.func,
  supportEmail: PropTypes.string,
  goToBilling: PropTypes.func,
  counter: PropTypes.number,
  showPayment: PropTypes.func,
  changeSection: PropTypes.func,
  event: PropTypes.object,
  language: PropTypes.object,
  getSubscriptions: PropTypes.func,
  checkIfOwe: PropTypes.func,
  calledFromAccount: PropTypes.bool,
};
