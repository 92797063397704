import React from 'react';
import PropTypes from 'prop-types';
import SingleProduct from './SingleProduct';
import PriceModal from './PriceModal';

import { getProductsClient } from '@actions';
import { connect } from 'react-redux';

class Products extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      prices: [],
      minPrice: {},
    };
    this.minPrice = {};
    this.changeModalStatus = this.changeModalStatus.bind(this);
    this.setProductPrices = this.setProductPrices.bind(this);
  }
  changeModalStatus() {
    this.setState((prevState) => ({ modalIsOpen: !prevState.modalIsOpen }));
  }
  setProductPrices(prices) {
    this.setState({ prices }, () => this.changeModalStatus());
  }
  componentDidMount() {
    this.props.getProductsClient();
  }
  render() {
    const { products, productsReady, language, color, currency } = this.props;
    const loadingCards = [];
    const productCards = [];

    products.map((product) => {
      this.minPrice[product.id] = Infinity;
      product.prices.map((price) => {
        if (price.sellingPrice < this.minPrice[product.id]) {
          this.minPrice[product.id] = price.sellingPrice;
        }
      });
      productCards.push(
        <SingleProduct
          key={product.id}
          product={product}
          language={language}
          modalIsOpen={this.state.modalIsOpen}
          setProductPrices={this.setProductPrices}
          color={color}
          minPrice={this.minPrice[product.id]}
          currency={currency}
        />
      );
    });
    for (let i = 1; i < 5; i++) {
      loadingCards.push(<SingleProduct key={i} loading={true} />);
    }

    return (
      <React.Fragment>
        <div
          className="container"
          style={
            this.state.modalIsOpen
              ? { filter: 'blur(1px) grayscale(50%)' }
              : null
          }>
          <div className="card-container">
            {productsReady ? productCards : loadingCards}
          </div>
        </div>
        {this.state.modalIsOpen && (
          <PriceModal
            prices={this.state.prices}
            changeModalStatus={this.changeModalStatus}
            language={language}
            color={color}
            currency={currency}
          />
        )}
      </React.Fragment>
    );
  }
}

Products.propTypes = {
  getProductsClient: PropTypes.func.isRequired,
  productsReady: PropTypes.bool.isRequired,
  products: PropTypes.array.isRequired,
  language: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    products: state.api.products.data,
    productsReady: state.api.products.ready,
    language: state.api.language.selected,
    color: state.api.event.data.clientPanelSettings.general.colors.primary,
    currency: state.api.event.data.currencySymbol,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getProductsClient: () => dispatch(getProductsClient()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Products);
