import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Table from '@components/layout/Table';
import MobileTable from '@components/layout/MobileTable';
import Agenda from '../Agenda';
import Rooms from '../Rooms/Rooms';
import KMBLoader from '@components/layout/KMBLoader';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { Typography } from '@material-ui/core';
import Paginator from '@components/layout/Paginator';
export default class Overall extends React.Component {
  constructor(props) {
    super(props);
    this.timeout = setTimeout(() => {}, 0);

    this.state = {
      search: this.props.search || '',
      clearFilter: false,
      roomSelected: false,
      daySelected: false,
    };
    this.format = {
      day: 'DD/MM/YYYY ',
      time: ' HH:mm A',
      year: 'YYYY',
    };

    this.columns = {
      cosmetic: {
        name: '',
      },
      name: {
        name: window.lang[this.props.language]['name'],
        sortable: true,
      },
      date: {
        name: window.lang[this.props.language]['date'],
        sortable: true,
      },
      chairpersons: {
        name: window.lang[this.props.language]['chairpersons'],
      },
      room: {
        name: window.lang[this.props.language]['room'],
      },
      sessionFiles: {
        name: window.lang[this.props.language]['files'],
      },
      stream: {
        name: window.lang[this.props.language]['stream'],
      },
      // addToAgenda: {
      //   name: window.lang[this.props.language]['addToAgenda'],
      // },
    };

    this.onRender = this.onRender.bind(this);
    this.getFormattedDay = this.getFormattedDay.bind(this);
    this.setRoomSelected = this.setRoomSelected.bind(this);
    this.setDaySelected = this.setDaySelected.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.loadSessions();
    if (this.props.mode === 'overall') {
      this.props.getRooms(this.props.event.id);
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  setRoomSelected(setRoom = true) {
    this.setState({ roomSelected: setRoom });
  }

  setDaySelected(setDay = true) {
    this.setState({ daySelected: setDay });
  }

  onRender(col, item) {
    const primaryColor =
      this.props.event &&
      this.props.event.clientPanelSettings &&
      this.props.event.clientPanelSettings.general.colors.primary;
    switch (col.key) {
      case 'name': {
        return (
          <span>
            {col.value}
            <span className="d-lg-none">{this.getFormattedDay(item)}</span>
          </span>
        );
      }

      case 'cosmetic': {
        return <span className="icon-seasson" />;
      }
      case 'chairpersons': {
        return item.chairpersons.map((cp) => {
          const isSpeakerChairperson = cp.eventSpeakerChairpersonId !== null;
          return (
            <div
              style={{
                color: isSpeakerChairperson ? '#2C5ABA' : 'black',
                cursor: 'default',
              }}
              onClick={(e) => {
                e.stopPropagation();
                if (!isSpeakerChairperson) return;
                this.props.showSpeaker(cp.eventSpeakerChairpersonId);
              }}
              key={cp.email}>
              {cp.firstName + ' ' + cp.lastName}
            </div>
          );
        });
      }
      case 'sessionFiles': {
        return (
          <span
            className={`icon-attach${col.value.length > 0 ? '' : '-no'}`}
            style={{ cursor: col.value.length === 0 && 'not-allowed' }}
          />
        );
      }

      case 'room': {
        return col.value.name;
      }

      case 'stream': {
        const stream =
          this.props.event.publishVideos &&
          ((item.selfHostingVideo && item.videoUrl && [item.id, 0]) ||
            (item.thirdPartyHosting && item.srcUrl && [item.id, 0]));

        return (
          <div
            onClick={(e) => {
              e.stopPropagation();
              if (stream && this.props.loggedIn) {
                this.props.showVideo(...stream);
              } else if (!this.props.loggedIn) {
                this.props.changeSection('login');
              }
            }}>
            {stream ? (
              <span>
                {item.videoUrl ? (
                  <i
                    className="icon-video"
                    style={{
                      color: primaryColor ? primaryColor : 'var(--primary-600)',
                    }}
                  />
                ) : (
                  <i
                    className="icon-video"
                    style={{
                      color: primaryColor ? primaryColor : 'var(--primary-600)',
                    }}
                  />
                )}
              </span>
            ) : (
              <i className="icon-no-video" style={{ cursor: 'not-allowed' }} />
            )}
          </div>
        );
      }

      case 'addToAgenda': {
        const className = '';

        return (
          <a
            style={{
              opacity: item.users.includes(this.props.user.id) ? 0.7 : 1,
            }}
            className={`add-to-agenda${className}`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (this.props.loggedIn) {
                this.props.userInSession(
                  item.id,
                  item.users.includes(this.props.user.id)
                );
              }
            }}
            title={
              item.users.includes(this.props.user.id)
                ? 'Remove from your agenda'
                : 'Add to your agenda'
            }>
            <i
              style={{
                textAlign: 'center',
                color:
                  item.users.includes(this.props.user.id) && primaryColor
                    ? primaryColor
                    : 'var(--primary-600)',
              }}
              className={`icon-agenda${
                item.users.includes(this.props.user.id) ? '-added1' : '-added'
              }`}
            />
          </a>
        );
      }

      case 'date': {
        return this.getFormattedDay(item);
      }
    }
  }

  getFormattedDay(item) {
    const start = moment(item.startDate.tz);
    const end = moment(item.endDate.tz);

    const sameDay = start.isSame(end, 'days');

    if (sameDay) {
      return (
        <p>
          <span className="date">{moment(start).format(this.format.day)}</span>
          <span className="time">
            {moment(start).format(this.format.time)} -{' '}
            {moment(end).format(this.format.time)}
          </span>
        </p>
      );
    }

    return (
      <div>
        <p>
          <span className="date">{moment(start).format(this.format.day)}</span>
          <span className="time">{moment(start).format(this.format.time)}</span>
        </p>
        <p>
          <span className="date">{moment(end).format(this.format.day)}</span>
          <span className="time">{moment(end).format(this.format.time)}</span>
        </p>
      </div>
    );
  }
  render() {
    const sessionRooms = {};
    this.props.rooms.map((room) => {
      sessionRooms[room.id] = room.name;
    });
    return (
      <div>
        <div className="control-container">
          {this.props.calendarReady ? (
            <React.Fragment>
              <Agenda
                loadSessions={this.props.loadSessions}
                clearStatus={this.state.clearFilter}
                setDaySelected={this.setDaySelected}
              />
              {JSON.stringify(sessionRooms) !== '{}' && (
                <Rooms
                  sessionRooms={sessionRooms}
                  loadSessions={this.props.loadSessions}
                  meta={this.props.meta}
                  language={this.props.language}
                  clearStatus={this.state.clearFilter}
                  setRoomSelected={this.setRoomSelected}
                />
              )}
              <div className="search-media-container">
                <div style={{ flex: 1 }}>
                  <div className="event-media">
                    {window.lang[this.props.language]['searchByTitle']}
                  </div>
                  <input
                    className="session-search"
                    placeholder={
                      window.lang[this.props.language]['searchByTitle']
                    }
                    value={this.state.search || ''}
                    onChange={(e) => {
                      clearTimeout(this.timeout);
                      this.setState({ search: e.target.value }, () => {
                        this.timeout = setTimeout(() => {
                          this.props.loadSessions({ search: e.target.value });
                        }, 1000);
                      });
                    }}
                  />
                </div>
                {this.props.event.scheduleFile.length > 0 && (
                  <div style={{ minWidth: '100px' }}>
                    <div className="event-media">&nbsp;</div>

                    <a
                      href={this.props.event.scheduleFile[0].url}
                      className="program-info">
                      <PictureAsPdfIcon />
                      <Typography>Download</Typography>
                    </a>
                  </div>
                )}
              </div>
            </React.Fragment>
          ) : (
            <KMBLoader rows={3} />
          )}
        </div>
        <div className="paginator-container sticky">
          <Paginator
            items={this.props.sessions}
            meta={this.props.meta}
            language={this.props.language}
            onChange={(meta) =>
              this.props.loadSessions(
                Object.assign({}, meta, {
                  order: 'ASC',
                  orderBy: 'startDate',
                })
              )
            }
            fetching={this.props.sessionsFetching}
          />
        </div>
        {this.props.sessions.length === 0 ||
        this.props.event.publishSchedule === 0 ? (
          <React.Fragment>
            <Table
              id={'list-sessions'}
              columns={this.columns}
              items={[]}
              onRender={this.onRender}
            />
            <div className="empty-results border-top">
              <span className="icon-warning-2" />
              <p>{window.lang[this.props.language]['scheduleNotPublished']}</p>
            </div>
          </React.Fragment>
        ) : (
          <div>
            <div className="session-list">
              {this.props.sessionsFetching ? (
                <KMBLoader rows={10} />
              ) : (
                <Table
                  id={'list-sessions'}
                  columns={this.columns}
                  items={this.props.sessions}
                  onRender={this.onRender}
                  onTrClick={(s) => this.props.showSpeeches(s.id)}
                  sortKey={
                    this.props.meta.orderBy === 'date'
                      ? 'startDate'
                      : this.props.meta.orderBy
                  }
                  onSort={(orderBy) => {
                    let order = this.props.meta.order;
                    if (orderBy === 'date') orderBy = 'startDate';
                    if (orderBy === this.props.meta.orderBy) {
                      order = order === 'ASC' ? 'DESC' : 'ASC';
                    }
                    this.props.loadSessions({ order, orderBy });
                  }}
                />
              )}
            </div>
            <div className="mobile-table">
              <MobileTable
                columns={this.columns}
                items={this.props.sessions}
                onRender={this.onRender}
                onTrClick={this.props.showSpeeches}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

Overall.propTypes = {
  sessions: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired,
  loadSessions: PropTypes.func.isRequired,
  showSpeeches: PropTypes.func.isRequired,
  addToAgenda: PropTypes.func.isRequired,
  agenda: PropTypes.array.isRequired,
  mode: PropTypes.string.isRequired,
  event: PropTypes.object.isRequired,
  showVideo: PropTypes.func.isRequired,
  changeSection: PropTypes.func.isRequired,
  loggedIn: PropTypes.bool,
  userInSession: PropTypes.func,
  user: PropTypes.object,
  language: PropTypes.string,
  calendarReady: PropTypes.bool,
  rooms: PropTypes.array,
  sessionsFetching: PropTypes.bool,
  search: PropTypes.string,
  getRooms: PropTypes.func,
  showSpeaker: PropTypes.func,
};
