import React from 'react';
import { connect } from 'react-redux';
// import { colorPalette } from "@config/colorPalette";
import {
  getUserNotifications,
  showAbstract,
  notificationsRead,
  logout,
} from '@actions';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import moment from 'moment';

class UserNotifications extends React.Component {
  constructor(props) {
    super(props);
    ['togglePanel', 'generateMarkup', 'close'].forEach(
      (fn) => (this[fn] = this[fn].bind(this))
    );
    this.state = {
      isMobile: props.isMobile,
      visible: false,
      notifications: [],
      unread: 0,
    };
    this.panel = null;
  }

  generateMarkup(n) {
    const { showAbstract } = this.props;
    let title = n.notificationSectionDescription;

    Object.entries(n.referenceData).map(([r, k]) => {
      title = title.replace(`{{${r}}}`, k);
    });
    switch (n.notificationSectionName) {
      case 'abstract_comment':
      case 'abstract_update_status':
      case 'abstract': {
        return (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              showAbstract(n.referenceData.abstractId);
              this.close();
            }}>
            {title}
          </a>
        );
      }
      default: {
        return title;
      }
    }
  }

  close(e) {
    if (this.state.isMobile) return;
    if (!e) {
      return this.setState({
        visible: false,
      });
    }
    if (this.panel !== null && !this.panel.contains(e.target)) {
      this.setState({
        visible: false,
      });
    }
  }
  UNSAFE_componentWillMount() {
    const { userId } = this.props;
    if (userId) {
      this.props.getUserNotifications().then((n) => {
        this.setState({
          notifications: n.data.notifications,
          unread: n.data.unread,
        });
      });
      if (this.props.isMobile) {
        document.addEventListener('click', this.close);
      }
    } else if (!userId) {
      document.removeEventListener('click', this.close);
    }
  }
  componentDidUpdate(prevProps) {
    const { userId } = this.props;
    if (userId !== prevProps.userId && userId) {
      this.props.getUserNotifications().then((n) => {
        this.setState({
          notifications: n.data.notifications,
          unread: n.data.unread,
        });
      });
      if (this.props.isMobile) {
        document.addEventListener('click', this.close);
      }
    } else if (!userId) {
      document.removeEventListener('click', this.close);
    } else if (prevProps.isMobile !== this.props.isMobile) {
      this.setState({ isMobile: this.props.isMobile });
    }
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.close);
  }

  componentDidMount() {
    this.props.notificationsRead();
  }

  togglePanel() {
    this.setState(
      {
        visible: !this.state.visible,
      },
      () => {
        if (this.state.visible) {
          if (this.state.unread > 0) {
            this.setState({
              unread: 0,
            });
          }
        }
      }
    );
  }

  returnDate(tzDate) {
    return moment(tzDate, 'YYYY-MM-DD HH:mm').format('D MMM, yyyy \\at HH:mm');
  }

  allAreRead() {
    return this.state.notifications.some((notif) => {
      if (notif.viewed) return true;
      return false;
    });
  }

  render() {
    const { notifications, unread } = this.state;

    return (
      <div className="user-notifications" ref={(ref) => (this.panel = ref)}>
        <div className="notifications-wrapper">
          <i className="icon-bell-regular" onClick={this.togglePanel} />
          {unread > 0 && <span className="count">{unread}</span>}
        </div>
        <Drawer
          className={this.state.isMobile ? '' : 'desktop-notification'}
          anchor={this.state.isMobile ? 'top' : 'right'}
          open={this.state.visible}
          onClose={() => this.togglePanel()}>
          <div className="mobile-notification-header">
            <div>{window.lang[this.props.language]['notifications']}</div>
            <div
              className="mark-as-read"
              onClick={this.props.notificationsRead}
              style={{
                color: this.allAreRead()
                  ? 'var(--gray-50)'
                  : window.colorPalette.primary[600],
                textDecorationColor: this.allAreRead()
                  ? 'var(--gray-50)'
                  : window.colorPalette.primary[600],
              }}>
              {window.lang[this.props.language]['markAllAsRead']}
            </div>
          </div>
          <div
            className={`mobile-notification-wrapper ${
              notifications.length === 0 ? 'empty-notifications' : ''
            }`}>
            {notifications.length === 0 ? (
              <div className="empty-notifications-text">
                {window.lang[this.props.language]['noNewNotifications']}
              </div>
            ) : (
              notifications.map((notif) => {
                return (
                  <div
                    key={notif.id}
                    className={`notification notification-${notif.id}`}
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.showAbstract(notif.referenceData.abstractId);
                      this.togglePanel();
                    }}>
                    <div className="viewed-indicator">
                      <div
                        className="viewed-dot"
                        style={{
                          backgroundColor: notif.viewed
                            ? 'var(--gray-50)'
                            : window.colorPalette.primary[600],
                        }}
                      />
                    </div>
                    <div className="notification-description">
                      <div className="notification-title">
                        {this.generateMarkup(notif)}
                      </div>
                      <div className="creation-date">
                        {this.returnDate(notif.createdAt.tz)}
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </Drawer>
      </div>
    );
  }
}

UserNotifications.propTypes = {
  getUserNotifications: PropTypes.func,
  notifications: PropTypes.object,
  showAbstract: PropTypes.func,
  notificationsRead: PropTypes.func,
  eventId: PropTypes.number,
  policyId: PropTypes.number,
  userId: PropTypes.number,
  color: PropTypes.string,
  language: PropTypes.string,
  isMobile: PropTypes.bool,
};

export default connect(
  (state) => ({
    notifications: state.api.userNotifications.data,
    eventId: state.api.policy.data.eventId,
    policyId: state.api.policy.data.id,
    userId: state.api.user.data.id,
    language: state.api.language.selected,
  }),
  { getUserNotifications, showAbstract, notificationsRead, logout }
)(UserNotifications);
