import React from 'react';
import PropTypes from 'prop-types';
import { getType } from '@helpers';
import Select from '@components/layout/Select';
import Radio from '@components/layout/Radio';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ForgotPasswordIcon from '@dynamic_assets/forgotPassword.svg.js';
import CheckMail from '@dynamic_assets/checkMail.svg.js';
import ResetSuccessful from '@dynamic_assets/resetSuccessful.svg.js';
import TermsOfService from '@components/layout/TermsOfService';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import Recaptcha from '@components/layout/Recaptcha';
import configuration from '@config/conf';
import { checkIfConditionIsFulfilled } from '../../../helpers';

const queryString = require('query-string');

export class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    [
      'prepareFields',
      'onChange',
      'clearEmail',
      'comparePasswords',
      'goToLogin',
    ].forEach((fn) => (this[fn] = this[fn].bind(this)));

    this.query = queryString.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    this.props.setCaptchaToken(null, 'login');

    if (this.query.t && !this.query.p && !this.query.q) {
      this.props.changeScreen('signup');
    } else if (this.query.t && this.query.p === 'true') {
      //case reset password
      this.props.changeScreen('resetpassword');
    } else if (this.query.t && this.query.q === 'true') {
      //case questionnaire
      this.props.changeScreen('questionnaire');
    } else if (this.query.registration === 'true') {
      //case registration
      this.props.changeScreen('register');
    } else if (this.props.user.loggedIn && this.props.user.data.id) {
      this.props.changeSection('generalInfo');
    }
    this.state = {
      fields: this.prepareFields(props.policy.fields, props.screen),
      valid: false,
      verified: null,
      agreeToTerms: false,
      termsError: false,
      validError: false,
      confirmField: '',
      passwordsMatch: false,
      targetEmail: '',
      validatedUser: {},
      passwordMismatchError: false,
      isCapsLockOn: false,
      fieldsComplete: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.screen === 'resetpassword' &&
      this.props.screen !== 'resetpassword'
    ) {
      if (!this.query.t || !this.query.p) {
        return;
      } else {
        this.props
          .validateToken(this.query.t)
          .then((resp) => {
            this.props.screen === 'resetpassword'
              ? this.setState({ validatedUser: resp.data, verified: 1 })
              : this.setState({ validatedUser: resp.data });
            this.props.changeScreen('resetpassword', resp.data.info.email);
          })
          .catch(() => {
            this.setState({ verified: 0 });
          });
      }
    }

    if (nextProps.screen !== this.props.screen) {
      this.setState({
        fields: this.prepareFields(nextProps.policy.fields, nextProps.screen),
      });
    }
  }

  componentDidMount() {
    this.props.toggleMobile();
    window.addEventListener('resize', this.props.toggleMobile);
    this.setState({
      valid: this.checkFieldsValidity(this.state.fields),
    });
  }

  goToLogin() {
    this.clearEmail();
    this.props.changeSection('login');
    this.props.changeScreen('login');
  }

  deriveYesNoValue(val) {
    if (val?.length > 0) {
      if (val === 1 || val === 'yes') {
        return 'yes';
      } else if (val === 0 || val === 'no') {
        return 'no';
      }
    } else {
      return 'no';
    }
    return val;
  }

  prepareFields(fields, screen) {
    const preparedFields = {};
    if (!fields) return preparedFields;
    [...fields]
      .filter((field) => {
        if (screen === 'forgotpassword') {
          return field.key === 'email';
        } else if (
          screen === 'forgotPassSuccess' ||
          screen === 'resetPassSuccess'
        ) {
          return false;
        } else if (screen === 'resetpassword') {
          return field.key === 'password';
        } else if (this.props.policy.type === 'public') {
          return field.required || field.askUser || field.used;
        } else {
          return field.identifier;
        }
      })
      .map((f, index) => {
        if (f.hasOwnProperty('value')) {
          if (typeof f.value === 'string') {
            try {
              f.value = JSON.parse(f.value);
            } catch (e) {
              console.error(e);
            }
          }
        }
        if (f.type === 'yes_no') {
          f.value = this.deriveYesNoValue(f.value);
        }
        preparedFields[f.key] =
          f.type === 'dropdown'
            ? {
                options: f.value,
                required: f.required,
                conditionSchema: f.conditionSchema,
                index,
                label: f.name,
                type: f.type,
                value: '',
              }
            : typeof f.type === 'undefined'
            ? {
                value: '',
                conditionSchema: f.conditionSchema,
                required: f.required,
                index,
                label: f.name,
              }
            : {
                value: f.value,
                conditionSchema: f.conditionSchema,
                required: f.required,
                index,
                label: f.name,
                type: f.type,
              };
      });
    return preparedFields;
  }

  comparePasswords(value, isConfirmField) {
    const match = isConfirmField
      ? value === this.state.fields.password.value
      : value === this.state.confirmField;

    this.setState({ passwordMismatchError: !match });
    return match;
  }

  setConfirmField(passValue) {
    this.setState({
      passwordsMatch: this.comparePasswords(passValue, true),
      confirmField: passValue,
    });
  }

  getConfirmPassField() {
    const confirmPassFieldClass = this.state.passwordMismatchError
      ? 'form-group has-error'
      : 'form-group';

    return (
      <div key={`field-password login-field`} className={confirmPassFieldClass}>
        <label className="input-label">
          {window.lang[this.props.language]['confirmPassword']}
        </label>
        <input
          onKeyDown={(e) => this.handleCapsLock(e)}
          onKeyUp={(e) => this.handleCapsLock(e)}
          className="form-control"
          value={this.state.confirmField}
          type={this.props.visibility.confirmPassword ? 'text' : 'password'}
          placeholder={
            window.lang[this.props.language][`confirmPasswordFieldPlaceholder`]
          }
          onChange={(e) => this.setConfirmField(e.target.value)}
          name="confirmPassword"
          required={true}
        />
        {this.state.verified !== 0 && (
          <span
            style={{
              marginTop: this.props.policy.type === 'public' ? '6px' : '-7px',
              color: window.colorPalette.primary[500],
              cursor: 'pointer',
            }}
            className="password-visibility"
            onClick={() =>
              this.props.togglePasswordVisibility('confirmPassword')
            }>
            {this.props.visibility.confirmPassword ? (
              <VisibilityOffIcon />
            ) : (
              <VisibilityIcon />
            )}
          </span>
        )}
      </div>
    );
  }

  getJSX(f, k) {
    const fieldShouldRender = checkIfConditionIsFulfilled(f, this.state.fields);
    if (!fieldShouldRender) {
      if (
        this.state.fields[f.key]?.value != undefined &&
        this.state.fields[f.key]?.value != ''
      ) {
        this.setState((prevState) => {
          const fields = Object.assign({}, prevState.fields);
          fields[f.key].value = '';
          return {
            fields,
          };
        });
        return null;
      }
      return null;
    }
    switch (f.type) {
      case 'dropdown': {
        const options = {};
        [...this.state.fields[k].options].map((v) => (options[v] = v));
        return (
          <span>
            <label>{`${f.label}${f.required ? ' *' : ''}`}</label>
            <Select
              placeholderInsideSelect={f.required === 1 ? false : true}
              placeholder={window.lang[this.props.language]['select']}
              required={f.required}
              value={this.state.fields[k].value}
              options={options}
              onChange={(value) =>
                this.onChange({ target: { name: k, value } })
              }
            />
          </span>
        );
      }
      case 'textarea': {
        return (
          <span>
            <label>{`${f.label}${f.required ? ' *' : ''}`}</label>
            <textarea
              className="form-control"
              required={f.required}
              value={this.state.fields[f.key].value}
              rows="5"
              onChange={this.onChange}
              name={f.key}
              placeholder={`${f.name || f.label}${f.required ? ' *' : ''}`}
            />
          </span>
        );
      }
      case 'text': {
        return (
          <input
            onKeyDown={(e) => this.handleCapsLock(e)}
            onKeyUp={(e) => this.handleCapsLock(e)}
            type={f.type}
            required={f.required}
            className="form-control"
            value={this.state.fields[k].value}
            autoComplete="off"
            onChange={this.onChange}
            name={k}
            placeholder={`${f.name || f.label}${f.required ? ' *' : ''}`}
          />
        );
      }

      case 'yes_no': {
        const val = this.deriveYesNoValue(this.state.fields[f.key].value);
        if (this.props.policy.type !== 'public') {
          return (
            <div className="kmb-radio">
              <Radio
                checked={val}
                id={'radio-input-' + k}
                onChange={(value) =>
                  this.onChange({ target: { name: k, value } })
                }
              />
            </div>
          );
        } else {
          return (
            <span>
              <label>{`${f.label}${f.required ? ' *' : ''}`}</label>
              <div className="kmb-radio">
                <Radio
                  checked={val}
                  onChange={(value) =>
                    this.onChange({ target: { name: k, value } })
                  }
                />
              </div>
            </span>
          );
        }
      }
    }
  }

  onChange(e) {
    const { name, value } = e.target;
    const fields = JSON.parse(JSON.stringify(this.state.fields));
    let passwordsMatch = this.state.passwordsMatch;
    for (const [k] of Object.entries(fields)) {
      if (k === name) {
        fields[k].value = value;
        if (k === 'password') {
          passwordsMatch = this.comparePasswords(value, false);
        }
      }
    }

    const fieldsComplete =
      fields.email?.value.trim() !== '' &&
      fields.password?.value?.trim() !== '';

    const isValid = this.checkFieldsValidity(fields);
    this.setState({
      fields,
      passwordsMatch,
      valid: isValid,
      validError: false,
      fieldsComplete,
    });
  }

  checkFieldsValidity(fields) {
    let valid = true;
    for (const [k, f] of Object.entries(JSON.parse(JSON.stringify(fields)))) {
      const key = f.key || k;
      const conditionIsFulfilled = checkIfConditionIsFulfilled(
        fields[key],
        fields
      );
      if (f.required && conditionIsFulfilled && !f.value) {
        valid = false;
      }
    }
    return valid;
  }

  clearEmail() {
    const fields = { ...this.state.fields };
    if (fields.email) fields.email.value = '';
    this.setState({ fields, valid: false });
  }

  getSubmitText() {
    if (this.props.screen === 'forgotpassword') {
      if (this.props.cooldown > 0)
        return `Please wait ${this.parseCooldownTime(
          this.props.cooldown
        )} and to reset your password.`;
      return window.lang[this.props.language]['resetYourPassword'];
    } else if (
      this.props.screen === 'resetpassword' &&
      this.state.verified === 1
    ) {
      return window.lang[this.props.language]['submitPassword'];
    } else if (this.state.verified === 0) {
      return window.lang[this.props.language]['ok'];
    }
    return window.lang[this.props.language]['login'];
  }

  renderLoginMessage() {
    return (
      <div className="login-info-wrapper">
        {this.props.policy.loginInfo.replace(
          /(\(.*?\))(\[.*?\])/g,
          function (match, p1, p2) {
            return `<a href="${p2
              .substring(0, p2.length - 1)
              .slice(1)}" target="_blank">${p1
              .substring(0, p1.length - 1)
              .slice(1)}</a>`;
          }
        )}
      </div>
    );
  }

  renderRegistrationText() {
    return (
      <div className="login-info-wrapper">
        {this.props.policy.registrationInfo}
      </div>
    );
  }

  updateFieldsComplete(fieldsComplete) {
    this.setState({ fieldsComplete });
  }

  returnSubmitButton() {
    if (
      this.props.screen === 'forgotPassSuccess' ||
      this.props.screen === 'resetPassSuccess'
    )
      return;
    return (
      <div
        className={`submit-container ${
          this.props.screen === 'forgotpassword' ? 'forgot-password' : ''
        }`}>
        {this.state.isCapsLockOn && (
          <div className="alert alert-warning">
            {window.lang[this.props.language]['capsLockOn']}
          </div>
        )}
        {this.props.screen === 'resetpassword' &&
          this.state.passwordMismatchError && (
            <div className="alert alert-danger">
              {window.lang[this.props.language]['passwordsDoNoMatch']}
            </div>
          )}
        <button
          type="submit"
          disabled={
            (this.props.screen === 'forgotpassword' &&
              (!this.props.recaptchaPasswordIsValid ||
                this.props.cooldown > 0)) ||
            (this.props.screen === 'resetpassword' &&
              !this.state.passwordsMatch) ||
            (this.props.loginFailAttempts >= configuration.allowedNoCaptcha &&
              !this.props.recaptchaLoginIsValid)
          }
          className={`btn login ${
            this.state.fieldsComplete ? 'btn-primary' : ''
          } ${
            (this.state.valid && this.props.policy.type !== 'public') ||
            this.state.verified === 0 ||
            (this.props.policy.type === 'public' &&
              this.props.policy.disclaimer?.length > 0 &&
              this.state.valid) ||
            (this.props.policy.disclaimer?.length == 0 && this.state.valid) ||
            this.props.cooldown <= 0
              ? ''
              : 'disabled'
          }`}
          style={{
            backgroundColor: this.state.fieldsComplete
              ? 'var(--primary-600)'
              : 'var(--primary-400)',
            color: 'white',
          }}>
          {this.getSubmitText()}
        </button>
        {this.props.errorMessage && (
          <div className="alert danger">{this.props.errorMessage}</div>
        )}
      </div>
    );
  }

  reorderFields() {
    const fieldsArray = Object.keys(this.state.fields).map((key) => {
      return { ...this.state.fields[key], key };
    });

    // lastName is rendered fifth
    for (const i in fieldsArray) {
      if (fieldsArray[i].key === 'lastName') {
        fieldsArray.unshift(fieldsArray.splice(i, 1)[0]);
        break;
      }
    }
    // firstName is rendered fourth
    for (const i in fieldsArray) {
      if (fieldsArray[i].key === 'firstName') {
        fieldsArray.unshift(fieldsArray.splice(i, 1)[0]);
        break;
      }
    }
    // password is rendered third
    for (const i in fieldsArray) {
      if (fieldsArray[i].key === 'password') {
        fieldsArray.unshift(fieldsArray.splice(i, 1)[0]);
        break;
      }
    }
    // username is rendered second
    for (const i in fieldsArray) {
      if (fieldsArray[i].key === 'username') {
        fieldsArray.unshift(fieldsArray.splice(i, 1)[0]);
        break;
      }
    }
    // email is rendered first
    for (const i in fieldsArray) {
      if (fieldsArray[i].key === 'email') {
        fieldsArray.unshift(fieldsArray.splice(i, 1)[0]);
        break;
      }
    }

    return fieldsArray;
  }

  parseCooldownTime(cooldown) {
    let timeUnit = 'seconds';
    let finalCooldown = cooldown / 60;

    if (finalCooldown > 1) timeUnit = 'minutes';
    if (finalCooldown <= 1) finalCooldown = cooldown;
    else finalCooldown = Math.ceil(finalCooldown);

    return `${finalCooldown} ${timeUnit}`;
  }

  requestResetPassword(targetEmail, clearEmail) {
    if (this.props.cooldown > 0) return;
    this.setState({ targetEmail: targetEmail.value });
    this.props.requestResetPassword(targetEmail).then(() => {
      if (clearEmail) this.clearEmail();
    });
  }

  handleCapsLock(e) {
    const isCapsLockOn = e.getModifierState('CapsLock');
    this.setState({ isCapsLockOn });
  }

  render() {
    const props = this.props;
    const fieldsArray = this.reorderFields();
    const hasValidError =
      this.props.policy.type === 'public' && this.state.validError;
    const hasTermsError =
      this.props.policy.type === 'public' && this.state.termsError;

    return (
      <React.Fragment>
        <form
          autoComplete="off"
          id="login-form"
          className="login-form"
          onSubmit={(e) => {
            e.preventDefault();

            // check fields validity
            if (!this.state.valid) {
              this.setState({ validError: true });
              return false;
            }

            // for public user they must agree to terms of service
            if (
              this.props.policy.type === 'public' &&
              !this.state.agreeToTerms &&
              this.props.screen === 'login'
            ) {
              this.setState({ termsError: true });
              return false;
            }
            if (
              this.props.policy.type === 'public' &&
              !this.state.agreeToTerms &&
              this.props.policy.disclaimer?.length > 0
            ) {
              return false;
            }

            // if the token is not valid (referring to the reset password token)
            if (this.state.verified === 0) {
              return window.location.replace(location.href.split('?')[0]);
            }

            const { email, password } = this.props;
            switch (props.screen) {
              case 'forgotpassword':
                return this.requestResetPassword(this.state.fields.email, true);
              case 'resetPassSuccess':
                return props.login(
                  this.props.policy.eventId,
                  this.props.policy.id,
                  { email, password }
                );
              case 'login':
                return props.login(
                  this.props.policy.eventId,
                  this.props.policy.id,
                  { ...this.state.fields }
                );
              default:
                return props.resetPassword(
                  { ...this.state.fields },
                  this.query.t,
                  this.state.validatedUser.info.email
                );
            }
          }}>
          {this.props.screen === 'login' &&
            this.props.policy.type === 'private' &&
            !!this.props.policy.onlineRegistration && (
              <div className="submit-container">
                <button
                  type="submit"
                  className="btn login btn-primary"
                  onClick={() => props.changeScreen('register')}
                  style={{
                    height: '44px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  {`${window.lang[this.props.language]['haveNoAccount']}
                  `}
                </button>
                <div
                  style={{
                    display: 'flex',
                    gap: '24px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '40px',
                    marginBottom: '30px',
                  }}>
                  <div
                    style={{
                      width: '50%',
                      marginTop: '1px',
                      height: '2px',
                      borderRadius: '16px',
                      background: 'var(--gray-100)',
                    }}></div>
                  <span style={{ color: 'var(--gray-700)', fontSize: '20px' }}>
                    {window.lang[this.props.language]['or']}
                  </span>
                  <div
                    style={{
                      width: '50%',
                      marginTop: '1px',
                      height: '2px',
                      borderRadius: '16px',
                      background: 'var(--gray-100)',
                    }}></div>
                </div>
              </div>
            )}
          <div className="splash-screen-title">
            {window.lang[this.props.language][props.screen]}
          </div>

          {props.screen === 'login' &&
            this.props.policy.loginInfo?.length > 0 &&
            this.renderLoginMessage()}

          {props.screen === 'login' &&
          props.policy.type === 'public' &&
          props.policy.registrationInfo
            ? this.renderRegistrationText()
            : null}
          {this.props.screen === 'forgotPassSuccess' && (
            <div className="login-helper-header">
              <div className="login-helper-icon">
                <CheckMail
                  color1="var(--blue-100)"
                  color2="var(--blue-400)"
                  color3="var(--blue-50)"
                />
              </div>
              <div className="login-helper-title">
                {window.lang[this.props.language]['checkYourEmail']}
              </div>
              <div className="login-helper-message">
                {`${window.lang[this.props.language]['sentRecoveryEmailAt']} ${
                  this.state.targetEmail
                }.`}
              </div>
              {this.props.cooldown <= 0 && (
                <Recaptcha componentToVerify="passwordReset" />
              )}
              <div
                className={`login-helper-finalize-text ${
                  this.props.cooldown <= 0 ? 'recaptcha-adjustment' : ''
                }`}>
                {`${window.lang[this.props.language]['noMailReceived']} `}
                {this.props.cooldown > 0 ? (
                  `Please wait ${this.parseCooldownTime(
                    this.props.cooldown
                  )} and try again.`
                ) : (
                  <span
                    className={`redo-link`}
                    onClick={() => {
                      if (
                        !this.props.recaptchaPasswordIsValid ||
                        this.props.cooldown > 0
                      ) {
                        return;
                      }
                      this.requestResetPassword({
                        value: this.state.targetEmail,
                      });
                    }}>
                    {window.lang[this.props.language]['sendEmailAgain']}
                  </span>
                )}
              </div>
            </div>
          )}
          {this.props.screen === 'resetPassSuccess' && (
            <div className="login-helper-header">
              <div className="login-helper-icon">
                <ResetSuccessful
                  color1="var(--success-100)"
                  color2="var(--success-400)"
                  color3="var(--success-50)"
                />
              </div>
              <div className="login-helper-title">
                {window.lang[this.props.language]['passwordRestored']}
              </div>
              <div className="login-helper-message">
                {window.lang[this.props.language]['continueAfterPassReset']}
              </div>
              <div className="submit-container">
                <button
                  className="btn login btn-primary"
                  onClick={this.goToLogin}>
                  Login
                </button>
              </div>
            </div>
          )}
          {(this.props.screen === 'forgotpassword' ||
            this.props.screen === 'resetpassword') && (
            <div className="login-helper-header">
              <div className="login-helper-icon">
                <ForgotPasswordIcon
                  color1="var(--blue-100)"
                  color2="var(--blue-400)"
                  color3="var(--blue-50)"
                />
              </div>
              <div className="login-helper-title">
                {props.screen === 'forgotpassword' &&
                  window.lang[this.props.language]['forgotPasswordQuestion']}
                {props.screen === 'resetpassword' &&
                  window.lang[this.props.language]['setNewPassword']}
              </div>
              <div className="login-helper-message">
                {props.screen === 'forgotpassword' &&
                  window.lang[this.props.language]['forgotPasswordReassurance']}
                {props.screen === 'resetpassword' &&
                  window.lang[this.props.language]['newPasswordRequirements']}
              </div>
            </div>
          )}
          {fieldsArray.map((field) => {
            const fieldKey = field.key;
            const type = getType(fieldKey, props.visibility);
            return (
              <div key={`field-${fieldKey} login-field`} className="form-group">
                {this.state.verified !== 0 ? (
                  field.hasOwnProperty('type') ? (
                    this.getJSX(field, fieldKey)
                  ) : (
                    <React.Fragment>
                      {field.label?.length > 0 && (
                        <label className="input-label">
                          {`${
                            props.screen === 'resetpassword' &&
                            !!this.state.verified
                              ? window.lang[this.props.language]['new']
                              : ''
                          } ${field.label}${
                            field.required &&
                            this.props.screen !== 'forgotpassword' &&
                            this.props.screen !== 'resetpassword'
                              ? ' *'
                              : ''
                          }`}
                        </label>
                      )}
                      <input
                        type={type}
                        className="form-control"
                        placeholder={
                          window.lang[this.props.language][
                            `${fieldKey}FieldPlaceholder`
                          ]
                        }
                        onClick={(e) => {
                          this.handleCapsLock(e);
                        }}
                        onKeyUp={(e) => this.handleCapsLock(e)}
                        onKeyDown={(e) => {
                          this.handleCapsLock(e);
                          // this prevents going to register screen from login
                          if (e.key === 'Enter') e.preventDefault();
                        }}
                        onChange={this.onChange}
                        name={fieldKey}
                        required={field.required}
                        autoComplete="off"
                        value={field.value}
                      />

                      {fieldKey === 'password' && this.state.verified !== 0 && (
                        <span
                          style={{
                            marginTop:
                              this.props.policy.type === 'public'
                                ? '6px'
                                : '-7px',
                            color: window.colorPalette.primary[500],
                            cursor: 'pointer',
                          }}
                          className="password-visibility"
                          onClick={() =>
                            props.togglePasswordVisibility(fieldKey)
                          }>
                          {this.props.visibility.password ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </span>
                      )}
                    </React.Fragment>
                  )
                ) : (
                  <p className="expired-token">
                    {window.lang[this.props.language]['expiredTokenMessage']}
                  </p>
                )}
              </div>
            );
          })}
          {props.screen === 'resetpassword' &&
            !!this.state.verified &&
            this.getConfirmPassField()}
          {(this.props.screen === 'forgotpassword' ||
            this.props.screen === 'resetpassword') && (
            <div className="forgot-pass-recaptcha-positioner">
              <Recaptcha componentToVerify="passwordReset" />
            </div>
          )}
          {props.screen !== 'forgotpassword' &&
            props.screen !== 'resetpassword' &&
            props.screen !== 'forgotPassSuccess' &&
            props.screen !== 'resetPassSuccess' &&
            props.policy.type !== 'public' && (
              <div className="login-actions">
                <div className="remember-me-wrapper">
                  {/* Positional placeholder for future feature*/}
                </div>
                <div className="forgot-pass-wrapper">
                  <span
                    className="navigational"
                    onClick={() => {
                      props.changeScreen('forgotpassword');
                      this.clearEmail();
                    }}>
                    {window.lang[this.props.language]['requestNewPassword']}
                  </span>
                </div>
              </div>
            )}

          {this.props.policy.type === 'public' && (
            <TermsOfService
              checked={this.state.agreeToTerms}
              handleAgreeToTerms={(e) => {
                const termsError =
                  this.state.termsError && e.target.checked
                    ? false
                    : this.state.termsError;
                this.setState({
                  agreeToTerms: e.target.checked,
                  termsError,
                });
              }}
              {...this.props}
            />
          )}

          {hasValidError && (
            <div className="terms-not-agreed-error">
              {window.lang[this.props.language]['checkValidFieldErrorMsg']}
            </div>
          )}
          {!hasValidError && hasTermsError && (
            <div className="terms-not-agreed-error">
              {window.lang[this.props.language]['pleaseAgreeToTerms']}
            </div>
          )}

          {this.props.screen === 'login' &&
            this.props.loginFailAttempts >= configuration.allowedNoCaptcha && (
              <Recaptcha componentToVerify="login" />
            )}
          {this.props.screen === 'resetpassword'
            ? !!this.state.verified && this.returnSubmitButton()
            : props.screen !== 'forgotPassSuccess' ||
              props.screen !== 'resetPassSuccess'
            ? this.returnSubmitButton()
            : null}

          {(this.props.screen === 'forgotpassword' ||
            this.props.screen === 'forgotPassSuccess' ||
            this.props.screen === 'resetPassSuccess' ||
            this.props.screen === 'resetpassword') &&
            this.props.policy.type === 'private' && (
              <div className="go-to-screen-link">
                <span
                  className="navigational back-to-login"
                  onClick={this.goToLogin}>
                  <ArrowBackRoundedIcon className="go-back-icon" />
                  {window.lang[this.props.language]['backToLogin']}
                </span>
              </div>
            )}
        </form>
      </React.Fragment>
    );
  }
}

LoginPage.propTypes = {
  policy: PropTypes.object.isRequired,
  recaptchaLoginIsValid: PropTypes.bool.isRequired,
  recaptchaPasswordIsValid: PropTypes.bool.isRequired,
  loginFailAttempts: PropTypes.number.isRequired,
  user: PropTypes.object.isRequired,
  changeScreen: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  setCaptchaToken: PropTypes.func.isRequired,
  togglePasswordVisibility: PropTypes.func.isRequired,
  visibility: PropTypes.object.isRequired,
  screen: PropTypes.string.isRequired,
  email: PropTypes.string,
  password: PropTypes.string,
  resetPasswordAvailable: PropTypes.bool.isRequired,
  requestResetPassword: PropTypes.func.isRequired,
  validateToken: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  language: PropTypes.string,
  changeSection: PropTypes.func,
  resetPassword: PropTypes.func,
  toggleMobile: PropTypes.func,
  cooldown: PropTypes.number,
};

export default LoginPage;
